const color = {
  GPrimary: '#00BAC7',
  GSecondary: '#C6F4F8',
  GTertiary: '#EDFCFD',
  GQuaternary: '#77CAE4',
  GTransparency: 'rgba(0, 186, 199, 0.3)',

  WPrimary: '#FFFFFF',
  WSecondary: '#F3F3F3',
  WTransparency: 'rgba(255, 255, 255, 0.3)',

  TPrimary: '#666666',
  TSecondary: '#C4C4C4',
}

const mixins = {
  // flex
  flexBox: (direction = 'row', align = 'center', justify = 'center') => `
    display: flex;
    flex-direction: ${direction};
    align-items: ${align};
    justify-content: ${justify};
  `,

  // positions
  positionCenterX: (type = 'absolute') => {
    if (type === 'absolute' || type === 'fixed')
      return `
        position: ${type};
        left: 50%;
        transform: translateX(-50%);
      `
    return
  },

  positionCenterY: (type = 'absolute') => {
    if (type === 'absolute' || type === 'fixed')
      return `
        position: ${type};
        top: 50%;
        transform: translateY(-50%);
      `
    return
  },

  positionCenter: (type = 'absolute') => {
    if (type === 'absolute' || type === 'fixed')
      return `
        position: ${type};
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      `
    return
  },
}

const theme = {
  color,
  mixins,
}

export default theme
