import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

function Mission() {
  const { t } = useTranslation()
  return (
    <Container>
      <Header>
        <span>ABOUT US</span>
        <Title>Our Mission</Title>
      </Header>
      <Missions>{t('main_mission')}</Missions>
      <List>
        <Item>
          <img src="/assets/icons/business.png" alt="bussiness icon" />
          <Keyword>BUSINESS</Keyword>
          <MissionP>{t('main_mission_business')}</MissionP>
        </Item>
        <Item>
          <img src="/assets/icons/technology.png" alt="bussiness icon" />
          <Keyword>TECHNOLOGY</Keyword>
          <MissionP>{t('main_mission_technology')}</MissionP>
        </Item>
        <Item>
          <img src="/assets/icons/social.png" alt="bussiness icon" />
          <Keyword>SOCIAL</Keyword>
          <MissionP>{t('main_mission_social')}</MissionP>
        </Item>
      </List>
    </Container>
  )
}

export default Mission

const Container = styled.div`
  ${({ theme }) => theme.mixins.flexBox('column', 'center', 'flex-start')};
  width: 1440px;
  height: 1024px;
  @media all and (max-width: 768px) {
    width: 100%;
    height: 86rem;
  }
`
const MissionP = styled.p`
  white-space: pre-line;
  text-align: center;
`

const Header = styled.div`
  ${({ theme }) => theme.mixins.flexBox('column')};
  margin-top: 120px;

  span {
    display: block;
    width: 155px;
    height: 43px;
    background-color: ${({ theme }) => theme.color.GTertiary};
    border-radius: 100px;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 43px;
    letter-spacing: 0.3em;
    color: ${({ theme }) => theme.color.GPrimary};
  }
`

const Title = styled.h1`
  margin-top: 20px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 64px;
  line-height: 78px;
  color: ${({ theme }) => theme.color.GPrimary};

  @media all and (max-width: 768px) {
    font-size: 2.8rem;
  }
`

const Missions = styled.div`
  ${({ theme }) => theme.mixins.flexBox('column')};
  margin-top: 100px;
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  line-height: 50px;
  color: #6d6d6d;
  white-space: pre-line;

  @media all and (max-width: 768px) {
    font-size: 1rem;
    text-align: center;
    max-width: 22rem;
    word-break: keep-all;
    margin-top: 2rem;
    line-height: 2rem;
  }
`

const List = styled.div`
  margin-top: 100px;
  padding: 0 60px;
  width: 100%;
  ${({ theme }) => theme.mixins.flexBox('row', 'center', 'space-between')};

  @media all and (max-width: 768px) {
    flex-direction: column;
    margin-top: 0;
    padding: 0 1rem;
  }
`

const Item = styled.div`
  ${({ theme }) => theme.mixins.flexBox('column', 'center', 'center')};
  width: 500px;

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 40px;
    color: ${({ theme }) => theme.color.TPrimary};
  }

  @media all and (max-width: 768px) {
    width: 100%;
    margin-top: 3rem;
    text-align: center;
  }
`

const Keyword = styled.h3`
  margin: 24px 0 20px;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: 0.4em;
  color: ${({ theme }) => theme.color.GPrimary};
`
