import { initReactI18next } from 'react-i18next'

import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

import { getOptions } from './settings'

// on client side the normal singleton is ok
// eslint-disable-next-line import/no-named-as-default-member
i18next
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  // .use(LocizeBackend) // locize backend could be used on client side, but prefer to keep it in sync with server side
  .init({
    ...getOptions(),
    lng: undefined, // let detect the language on client side
    debug: false,
    detection: {
      order: ['cookie', 'navigator', 'path', 'htmlTag'],
      caches: ['localStorage', 'cookie'],
    },
  })

export default i18next
