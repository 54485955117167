import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

const styled = { createGlobalStyle }
const GlobalStyle = styled.createGlobalStyle`
  ${reset}

  /* other styles */
  * {
    box-sizing: border-box;
    font-family: 'Noto Sans KR', sans-serif;
  }
  html {
    ${({ theme }) => theme.mixins.flexBox('column')};
  }

  body {
    font-family: 'Noto Sans KR', sans-serif;
    max-width: 1440px;

    @media all and (max-width: 768px) {
      max-width: 100%;
    }
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  button {
    margin: 0;
    padding: 0;
  }
`
export default GlobalStyle
