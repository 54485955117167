export const fallbackLng = 'en'
export const languages = [fallbackLng, 'ko']
export const defaultNS = 'translation'
export const cookieName = 'i18next'

export function getOptions(lng = fallbackLng, ns = defaultNS) {
  return {
    debug: true,
    supportedLngs: languages,
    preload: languages,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  }
}
