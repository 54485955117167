import { useEffect, useState } from 'react'
import styled from 'styled-components'

function Partnership() {
  const [data, setData] = useState([])
  useEffect(() => {
    fetch('/data/Main/Partnership/data.json')
      .then((res) => res.json())
      .then((data) => {
        setData(data)
      })
  }, [])
  return (
    <Container>
      <Header>
        <span>ABOUT US</span>
        <Title>Partnership</Title>
      </Header>

      <Partner src="/assets/images/main/Partnership_01.png" />

      <img
        className="mo"
        src="/assets/images/main/partner/Partnership_mo.png"
        alt="partners"
      />
      <Background src="/assets/images/main/Partnership_back_02.png" />
    </Container>
  )
}

export default Partnership

const Container = styled.div`
  position: relative;
  ${({ theme }) => theme.mixins.flexBox('column', 'center', 'flex-start')};
  width: 1440px;
  height: 1024px;
  background: url('/assets/images/main/Partnership_back_01.png');
  .mo {
    display: none;
  }
  @media all and (max-width: 768px) {
    .mo {
      display: block;
      width: 100%;
    }
    background: url('/assets/images/main/Partnership_back_01.png');
    background-size: cover;
    width: 100%;
    height: 100%;
    padding: 0 0 120px 0;
    .bg {
      display: block;
      width: 19rem;
      position: absolute;
      right: 0;
      top: 16rem;
    }
  }
`

const LogoList = styled.div`
  display: none;
  @media all and (max-width: 768px) {
    background: url('/assets/images/main/mobile/partnership/ractangle.png');
    background-repeat: no-repeat;
    background-position: center;
    padding: 0 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    div {
      width: auto;
    }
    .bg-img {
      position: absolute;
      z-index: 0;
    }
  }
`

const Header = styled.div`
  z-index: 2;
  ${({ theme }) => theme.mixins.flexBox('column')};
  margin-top: 230px;

  span {
    display: block;
    width: 155px;
    height: 43px;
    background-color: #edfcfd;
    border-radius: 100px;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 43px;
    letter-spacing: 0.3em;
    color: ${({ theme }) => theme.color.GPrimary};
  }

  @media all and (max-width: 768px) {
    margin-top: 7rem;

    span {
      background-color: #c6f4f8;
    }
  }
`

const Title = styled.h1`
  margin-top: 20px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 64px;
  line-height: 78px;
  z-index: 2;
  color: ${({ theme }) => theme.color.GPrimary};
  @media all and (max-width: 768px) {
    font-size: 2.8rem;
  }
`
const Partner = styled.img`
  z-index: 2;
  margin-top: 80px;
  width: 1320px;

  @media all and (max-width: 768px) {
    display: none;
  }
`
const Background = styled.img`
  position: absolute;
  top: 100px;
  z-index: 1;
  @media all and (max-width: 768px) {
    display: none;
  }
`

const Background2 = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  /* top: 0px;
  z-index: 0; */
  background-image: url('/assets/images/main/partner/bg2.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 80px 0;
  z-index: 0;

  @media all and (max-width: 768px) {
  }
`
