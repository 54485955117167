import styled from 'styled-components'

function Terms() {
  return (
    <ContactWrapper>
      <div id="wrapper">
        <div class="contents" id="fullpage" data-ui-parellax="wrapper">
          <div class="contents--outer-wrap section">
            <div class="contents-wrapper">
              <article style={{ lineHeight: '2rem', paddingTop: '2rem' }}>
                <p>개인정보처리방침</p>
                <p>
                  <span>&nbsp;</span>
                </p>
                <p>
                  에스프레스토㈜(이하 '에스프레스토' 또는 '회사')는 이용자의
                  ‘동의를 기반으로 개인정보를 수집·이용 및 제공’하고 있습니다.
                </p>
                <p>
                  또한 서비스 기획부터 종료까지 정보통신망 이용촉진 및 정보보호
                  등에 관한 법률(이하 ‘정보통신망법’), 개인정보보호법 등 국내의
                  개인정보 보호 법령을 철저히 준수합니다.
                </p>
                <p>
                  “개인정보처리방침”이란 이용자의 소중한 개인정보를 보호함으로써
                  이용자가 안심하고 서비스를 이용할 수 있도록 회사가 준수해야 할
                  지침을 의미합니다. 본 개인정보처리방침의 목차는 아래와
                  같습니다.
                </p>
                <p>&nbsp;</p>
                <p>본 개인정보처리방침의 목차는 아래와 같습니다.</p>
                <p>
                  일부는 관계법령이 개인정보처리방침의 작성에 있어 요구하는 필수
                  사항을 포함하였고, 나머지는 에스프레스토가 자체적으로 이용자의
                  개인정보 보호에 있어 중요하다 판단하는 내용을 추가하였습니다.
                </p>
                <p>&nbsp;</p>
                <p>제 1 조 개인정보의 처리 목적</p>
                <p>제 2 조 개인정보 수집에 대한 동의 및 수집 방법</p>
                <p>제 3 조 개인정보 수집∙이용 목적 및 수집항목 등</p>
                <p>제 4 조 개인정보의 제3자 제공에 관한 사항</p>
                <p>제 5 조 개인정보처리 위탁</p>
                <p>제 6 조 정보주체와 법정대리인의 권리∙의무 및 그 행사방법</p>
                <p>제 7 조 (8세 이하의 아동 등의 보호의무자의권리)</p>
                <p>
                  제 8 조 (위치정보 이용ㆍ제공사실 확인자료 보유근거 및
                  보유기간)
                </p>
                <p>제 9 조 동의 철회</p>
                <p>제 10 조 개인정보의 파기</p>
                <p>
                  제 11 조 개인정보 자동수집 장치의 설치∙운영 및 그 거부에 관한
                  사항
                </p>
                <p>제 12 조 링크 사이트 제공</p>
                <p>제 13 조 맞춤형 광고 안내</p>
                <p>제 14 조 개인정보 보호책임자</p>
                <p>제 15 조 개인정보의 안정성 확보 조치</p>
                <p>제 16 조 개인정보 열람 및 정정</p>
                <p>제 17 조 정보주체의 권익침해에 대한 구제방법</p>
                <p>제 18 조 개인정보 처리방침의 개정과 그 공지</p>
                <p>
                  <br />제 1 조 (개인정보의 처리목적)
                </p>
                <p>
                  ① 개인정보란 생존하는 개인에 관한 정보로서 당해 정보에
                  포함되어 있는 성명, 주민등록번호 등의 사항에 의하여 당해
                  개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을
                  식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수
                  있는 것을 포함합니다.)를 말합니다.
                </p>
                <p>
                  ② 에스프레스토는 귀하의 개인정보보호를 매우 중요시하며,
                  『개인정보보호법』,『정보통신망 이용촉진 및 정보보호에 관한
                  법률』상의 개인정보보호규정 및 방송통신위원회가 제정한
                  『개인정보보호지침』을 준수하고 있습니다. 회사는
                  개인정보처리방침을 통하여 귀하께서 제공하시는 개인정보가
                  어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해
                  어떠한 조치가 취해지고 있는지 알려드립니다.
                </p>
                <p>
                  ③ 개인정보처리방침은 정부의 법령이나 지침의 변경, 또는 보다
                  나은 서비스의 제공을 위하여 그 내용이 변경될 수 있습니다. 이
                  경우 회사는 웹 사이트와 어플리케이션에서 공지사항으로 이를
                  올리거나 이메일을 통해서 공지하고 있습니다.
                </p>
                <p>
                  ④ 회사는 개인정보처리방침을 사이트 첫 화면 또는 첫 화면과의
                  연결화면을 통해 공개함으로써 이용자가 언제나 용이하게 보실 수
                  있도록 조치하고 있습니다.
                </p>
                <p>
                  ⑤ 회사는 개인정보처리방침의 지속적인 개선을 위하여
                  개인정보처리방침을 개정하는데 필요한 절차를 정하고 있습니다.
                </p>
                <p>
                  ⑥ 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적
                  이외의 용도로는 이용하지 않습니다.
                </p>
                <p>
                  - 고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인
                  식별·인증, 회원자격 유지·관리, 물품 또는 서비스 공급에 따른
                  금액 결제, 물품 또는 서비스의 공급·배송 등<br />
                  이 약관(이하 “약관”이라 합니다)은 에스프레스토(주) (이하
                  “회사”라 합니다)와 이용 고객(이하 “회원”이라 합니다)간에
                  회사가 제공하는 서비스(이하 “서비스”라 합니다)를 이용함에 있어
                  회원과 회사간의 권리, 의무 및 책임사항, 이용조건 및 절차 등
                  기본적인 사항을 규정함을 목적으로 합니다.
                  <br />
                  <br />제 2 조 (개인정보 수집에 대한 동의 및 수집 방법)
                </p>
                <p>&nbsp;</p>
                <p>
                  ① 회사는 이용자께서 회사의 개인정보처리방침 또는 이용약관의
                  내용에 대해 '동의함' 또는 '동의하지 않음'을 클릭할 수 있는
                  절차를 마련하여 '동의함'을 클릭하면 개인정보 수집에 대해
                  동의한 것으로 봅니다.
                </p>
                <p>
                  단, 회사는 다음 각 호의 어느 하나에 해당하는 경우에는 법령에
                  따라 이와 같은 동의 없이 이용자의 개인정보를 수집 및 이용할 수
                  있습니다.
                </p>
                <p>
                  (1) 정보통신서비스의 제공에 관한 계약을 이행하기 위하여 필요한
                  개인정보로서 경제적·기술적인 사유로 통상적인 동의를 받는 것이
                  뚜렷하게 곤란한 경우
                </p>
                <p>
                  (2) 정보통신서비스 제공에 따른 요금정산을 위하여 필요한 경우
                </p>
                <p>(3) 그 밖에 법률에 특별한 규정이 있는 경우</p>
                <p>
                  ② 회사가 개인정보를 수집하는 경우에는 반드시 사전에 이용자에게
                  해당 사실을 알리고 동의를 구하고 있으며, 아래와 같은 방법을
                  통해 개인정보를 수집합니다.
                </p>
                <p>
                  (1) 회사의 채널을 통헌 회원가입 및 서비스 이용과정에서
                  이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는
                  경우
                </p>
                <p>
                  (2) 제휴 서비스 또는 단체 등으로 부터 개인정보를 제공받는 경우
                </p>
                <p>
                  (3) 고객센터를 통한 상담 과정에서 웹페이지, 메일, 팩스, 전화
                  등을 통하는 경우
                </p>
                <p>
                  (4) 온·오프라인에서 진행되는 이벤트·행사·설문조사 등을 통한
                  경우
                </p>
                <p>&nbsp;</p>
                <p>제 3 조 (개인정보 수집∙이용 목적 및 수집항목 등)</p>
                <p>
                  에스프레스토는 정보주체로부터 개인정보를 수집할 때 동의 받은
                  개인정보 보유․이용기간 또는 법령에 따른 개인정보 보유․이용기간
                  내에서 개인정보를 처리․보유합니다.
                </p>
                <p>
                  ① 수집된 개인정보는 회원관리, 서비스 제공·개선, 신규 서비스
                  개발 등을 위해 이용합니다.
                </p>
                <p>
                  회원 가입 시 또는 서비스 이용 과정에서 홈페이지 또는 개별
                  어플리케이션이나 프로그램 등을 통해 아래와 같이 서비스 제공을
                  위해 필요한 최소한의 개인정보를 수집하고 있습니다.
                </p>
                <p>1) 회원 식별/가입의사 확인, 본인/연령 확인, 부정이용 방지</p>
                <p>
                  2) 만 14세 미만 아동 개인정보 수집 시 법정 대리인 동의여부
                  확인, 법정 대리인 권리행사 시 본인 확인
                </p>
                <p>3) 이용자간 메시지 전송, 친구등록 및 친구추천 기능의 제공</p>
                <p>
                  4) 친구에게 활동내역을 알리거나 이용자 검색, 등록 등의 기능
                  제공
                </p>
                <p>
                  5) 신규 서비스 개발, 다양한 서비스 제공, 문의사항 또는
                  불만처리, 공지사항 전달
                </p>
                <p>5) 유료서비스 이용 시 컨텐츠 등의 전송이나 배송·요금 정산</p>
                <p>
                  6) 서비스의 원활한 운영에 지장을 주는 행위(계정 도용 및 부정
                  이용 행위 등 포함)에 대한 방지 및 제재
                </p>
                <p>
                  7) 인구통계학적 특성과 이용자의 관심, 기호, 성향의 추정을 통한
                  맞춤형 컨텐츠 추천 및 마케팅에 활용
                </p>
                <p>8) 음성명령 처리 및 음성인식 향상, 개인화 서비스 제공</p>
                <p>
                  9) 서비스 이용 기록, 접속 빈도 및 서비스 이용에 대한 통계,
                  프라이버시 보호 측면의 서비스 환경 구축, 서비스 개선에 활용
                </p>
                <p>&nbsp;</p>
                <p>
                  ② 이용자는 회사의 서비스를 이용하시고자 할 경우 다음의
                  필수정보를 입력해주셔야 하며 선택항목을 입력하시지 않았다 하여
                  서비스 이용에 제한은 없습니다.
                </p>
                <p>(1) 회원</p>
                <p>1) 회원</p>
                <table width="100%">
                  <thead>
                    <tr>
                      <td>
                        <p>분류</p>
                      </td>
                      <td>
                        <p>수집∙이용동의 목적</p>
                      </td>
                      <td>
                        <p>항목</p>
                      </td>
                      <td>
                        <p>보유∙이용기간</p>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p>필수정보</p>
                      </td>
                      <td>
                        <p>
                          서비스 가입, 계약의 이행 및 서비스 제공,
                          예약∙구매∙관심상품 내역, 결제대금의 청구,
                          상담∙불만∙민원처리, 고지∙안내사항 전달, 상품∙서비스
                          이용실적 정보 통계∙분석, 상품∙서비스 개선 및 추천,
                          불법∙부정이용방지, 개인정보 유효기간제 준수
                        </p>
                      </td>
                      <td>
                        <p>
                          - 서비스 이용시: 아이디(이메일), 비밀번호, 프로필
                          사진, 닉네임, 디바이스 ID, 휴대폰 번호,
                          서비스이용기록, IP address, 접속로그, Cookie,
                          광고식별자, 단말기 OS와 버전, 단말기 모델명, 브라우저
                          버전
                          <br />
                          - 예약∙구매시: 예약자 및 구매자의 이름과 휴대폰 번호,
                          생년월일(필요한 서비스의 경우), 방문자(탑승자)의 이름,
                          휴대폰 번호, 생년월일(필요한 서비스의 경우), 방문자의
                          이름과 휴대폰 번호(예약자와 방문자가 다른 경우),
                          예약자의 이메일 주소와 투숙객의 영문 이름(해외숙소
                          예약시), 만17세 이하 자녀의 나이(해외숙소 예약시
                          동반하는 자녀가 있는 경우), 예약내역(예약일시,
                          결제금액, 업체명)
                          <br />- 현금환불요청시: 계좌번호 및 예금주명
                        </p>
                      </td>
                      <td>
                        <p>
                          - 회원 탈퇴 요청시 7일 후 삭제
                          <br />- 관계법령에 따라 보존할 필요가 있는 경우 해당
                          법령에서 요구하는 기한까지 보관 (예: 구매 회원의 경우
                          5년간, IP의 경우 3개월)
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>&nbsp;</p>
                <table width="100%">
                  <thead>
                    <tr>
                      <td>
                        <p>분류</p>
                      </td>
                      <td>
                        <p>수집∙이용동의 목적</p>
                      </td>
                      <td>
                        <p>항목</p>
                      </td>
                      <td>
                        <p>보유∙이용기간</p>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p>선택정보</p>
                      </td>
                      <td>
                        <p>
                          개인맞춤형 상품∙서비스 혜택에 관한 정보제공,
                          통계∙분석∙이용내역 정보와의 결합 및 분석, 회사 또는
                          기타 광고주의 요청에 따른
                          정보∙안내(프로모션∙이벤트)전송
                        </p>
                      </td>
                      <td>
                        <p>필수 항목으로 수집한 모든 정보</p>
                      </td>
                      <td>
                        <p>
                          - 회원 탈퇴 요청시 7일 후 삭제
                          <br />- 관계법령에 따라 보존할 필요가 있는 경우 해당
                          법령에서 요구하는 기한까지 보관 (예: 구매 회원의 경우
                          5년간, IP의 경우 3개월)
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>&nbsp;</p>
                <p>&nbsp;(2) 장기미이용회원</p>
                <p>
                  가. 장기미이용 회원은 에스프레스토 서비스를 최종 이용 후 1년
                  동안 이용 기록이 없는 회원을 말합니다.
                </p>
                <p>
                  나. 이 회원의 개인정보는 즉시 탈퇴 처리되며, 대상자에게 안내는
                  탈퇴 처리일 기준으로 최소 30일 이전과 탈퇴 처리 후 두 번에
                  걸쳐 이메일로 전송됩니다.
                </p>
                <p>
                  다. 장기미이용 회원이 탈퇴 처리전에 지속적으로 에스프레스토
                  서비스를 이용하시고자 하는 경우, 에스프레스토 서비스에 로그인
                  하시기를 요청 드립니다.
                </p>
                <p>
                  라. 회원가입 시 장기 미접속시에도 계정 유지를 선택한 회원은
                  장기미이용 회원이 되더라도 회원이 선택한 기간 동안 탈퇴
                  처리되지 않으며, 회원이 선택한 기간 또는 회원이 직접 탈퇴
                  신청할 때까지 개인정보를 보관합니다.
                </p>
                <p>마. 법령에 의해 수집 이용되는 회원 정보</p>
                <table width="100%">
                  <thead>
                    <tr>
                      <td>
                        <p>보유 정보</p>
                      </td>
                      <td width="23%">
                        <p>보유 기간</p>
                      </td>
                      <td width="34%">
                        <p>근거 법령</p>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p>계약 또는 청약철회 등에 관한 기록</p>
                      </td>
                      <td width="23%">
                        <p>5년</p>
                      </td>
                      <td rowspan="4" width="34%">
                        <p>전자상거래 등에서의 소비자보호에 관한 법률</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>대금결제 및 재화 등의 공급에 관한 기록</p>
                      </td>
                      <td width="23%">
                        <p>5년</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>소비자의 불만 또는 분쟁처리에 관한 기록</p>
                      </td>
                      <td width="23%">
                        <p>3년</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>표시•광고에 관한 기록</p>
                      </td>
                      <td width="23%">
                        <p>6개월</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>웹사이트 방문 기록</p>
                      </td>
                      <td width="23%">
                        <p>3개월</p>
                      </td>
                      <td width="34%">
                        <p>통신비밀보호법</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  <br />
                </p>
                <p>
                  ③ 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종
                  및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및
                  범죄기록, 의료정보 등)는 수집하지 않습니다.
                </p>
                <p>④ 별도 수집가능항목</p>
                <p>
                  일부 서비스에서는 특화된 여러 기능들을 제공하기 위해 회원가입
                  시 수집하지 않았던 개인정보를 이용자에게 동의를 받고 추가적인
                  개인정보를 수집할 수 있습니다.
                </p>
                <p>
                  (1) 필수정보 정의: 해당 서비스의 본질적 기능을 수행하기 위한
                  정보
                </p>
                <p>
                  (2) 선택정보 정의: 보다 특화된 서비스를 제공하기 위해 추가
                  수집하는 정보 (선택 정보를 입력하지 않은 경우에도 서비스 이용
                  제한은 없습니다.)
                </p>
                <p>&nbsp;</p>
                <p>[고객상담을 위해 추가 수집하는 개인정보]</p>
                <table width="100%">
                  <thead>
                    <tr>
                      <td width="18%">&nbsp;</td>
                      <td width="81%">
                        <p>추가적으로 수집하는 개인정보 항목</p>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td width="18%">
                        <p>고객센터</p>
                      </td>
                      <td width="81%">
                        <p>&lt;문의하기/신고하기&gt;</p>
                        <p>- 전화고객센터 : 발신전화번호</p>
                        <p>- 웹고객센터 : 이메일, 휴대폰번호</p>
                        <p>&lt;명예훼손/개인정보침해/저작권침해 신고&gt;</p>
                        <p>
                          - 게시물 차단 신청서 : 신청인정보(이름, 생년월일,
                          전화번호, 이메일 또는 팩스번호, 닉네임, 마스킹된
                          신분증 사본)
                        </p>
                        <p>
                          - (대리인 신고 시) 위임장 : 위임인/수임인정보(이름,
                          생년월일, 전화번호, 마스킹된 신분증 사본)
                        </p>
                        <p>&lt;명예훼손/개인정보침해에 대한 소명 요청&gt;</p>
                        <p>
                          - 게시물 복원 신청서 : 이름, 생년월일, 전화번호 또는
                          팩스번호, 이메일, 닉네임
                        </p>
                        <p>&lt;저작권침해에 대한 소명 요청&gt;</p>
                        <p>
                          - 복제,전송 재개 요청서 : 요청자/대리인 정보(이름,
                          생년월일, 전화번호, 이메일, 마스킹된 신분증 사본)
                        </p>
                        <p>&lt;자기게시물 접근배제 요청 시&gt;</p>
                        <p>
                          - (본인 신청 시) 자기게시물 접근배제 요청서 : 요청인
                          정보(이름, 전화번호, 이메일 또는 팩스번호, 마스킹된
                          신분증 사본), 자기게시물 입증자료에 포함된 개인정보
                        </p>
                        <p>
                          - (지정인 신청 시) 접근배제요청인 지정서 : 요청인
                          정보(이름, 전화번호, 이메일 또는 팩스번호, 마스킹 된
                          신분증 사본), 지정인 정보(이름, 전화번호, 이메일 또는
                          팩스번호, 신청인과의 관계)
                        </p>
                        <p>&lt;문의하기/신고하기 시 증빙서류&gt;</p>
                        <p>
                          - 관계 증명 시 : 마스킹된 증빙 서류(가족관계 증명서,
                          사망사실 증명서 등)
                        </p>
                        <p>
                          - 사업자/단체회원 정보 확인・변경 시 : 마스킹된
                          증빙서류(재직 증명서), 개인(사업자 등록증), 개인사업자
                          폐업 사실 증명서, 법인 인감증명서
                        </p>
                        <p>
                          - 본인 확인・정보 변경 시 : 마스킹된 증빙 서류(신분증
                          사본, 통신사 증빙서류, 인감증명서, 본인서명 사실
                          확인서)
                        </p>
                        <p>
                          - 주민등록번호변경 또는 실명정보 변경 시 : 마스킹된
                          증빙 서류(주민등록초본 사본, 기본 증명서, 국적 취득 /
                          상실 사실 증명서 등)
                        </p>
                        <p>
                          - 미성년자 확인 시 : 마스킹된 증빙 서류(학생증,
                          주민등록등본, 건강보험증 등)
                        </p>
                        <p>- 권한 위임 시 : 위임장</p>
                        <p>
                          ※ 고객센터로 문의/신고 시 위와 같은 정보를 수집할 수
                          있으며, 문의/신고 유형에 따라 휴대폰 번호 등
                          회원님께서 추가로 입력하시는 개인정보가 있을 수
                          있습니다.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>제 4 조 (개인정보의 제3자 제공에 관한 사항)</p>
                <p>
                  ① 에스프레스토는 정보주체의 동의, 법률의 특별한 규정 등
                  개인정보 보호법 제17조 및 제18조에 해당하는 경우에만
                  개인정보를 제3자에게 제공합니다.
                </p>
                <p>
                  ② 회사는 원칙적으로 이용자 동의 없이 개인정보를 외부에
                  제공하지 않습니다.
                </p>
                <p>
                  정보주체의 사전 동의 없이 개인정보를 외부에 제공하지 않습니다.
                  단, 이용자가 외부 제휴사의 서비스를 이용하기 위하여 개인정보
                  제공에 직접 동의를 한 경우, 그리고 관련 법령에 의거해 네이버에
                  개인정보 제출 의무가 발생한 경우, 이용자의 생명이나 안전에
                  급박한 위험이 확인되어 이를 해소하기 위한 경우에 한하여
                  개인정보를 제공하고 있습니다.
                </p>
                <p>
                  다만, 아래와 같이 양질의 서비스 제공을 위해 상품 예약. 구매
                  단계에서 개인정보를 제공 받는 자, 제공목적, 제공 항목, 이용 및
                  보유기간을 회원에게 고지하여 동의를 구한 후 제3자에게
                  제공합니다.
                </p>
                <p>&nbsp;</p>
                <table width="100%">
                  <thead>
                    <tr>
                      <td>&nbsp;</td>
                      <td>
                        <p>제공 목적</p>
                      </td>
                      <td>
                        <p>제공 항목</p>
                      </td>
                      <td>
                        <p>이용 및 보유기간</p>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          불법카메라 안심 서비스 제공업체 또는 숙박,임대업소
                          홍보 및 판매 제공 서비스
                        </p>
                      </td>
                      <td>
                        <p>
                          예약∙구매한 상품∙서비스의 제공 및 계약의 이행(이용자
                          및 이용정보 확인), 민원처리 등 소비자 분쟁해결
                        </p>
                      </td>
                      <td>
                        <p>
                          예약번호, 예약자 정보(예약자명, 휴대폰 번호) 또는
                          방문자 정보(방문자명, 휴대폰 번호)
                        </p>
                      </td>
                      <td>
                        <p>
                          서비스 이용 종료 일로부터 7일 후 삭제. 단, 관계법령에
                          의하여 보존할 필요성이 있는 경우 그 시점까지 보존 후
                          지체 없이 파기
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>&nbsp;</p>
                <p>③ 전항에도 불구하고, 다음의 경우에는 예외로 합니다.</p>
                <p>
                  1) 관계법령에 의하여 수사 등의 목적으로 관계기관으로부터 제공
                  요구가 있을 경우
                </p>
                <p>
                  2) 통계작성, 학술연구나 시장조사를 위하여 특정 개인을 식별할
                  수 없는 형태로 광고주 협력사나 연구단체 등에 제공하는 경우
                </p>
                <p>3) 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우</p>
                <p>&nbsp;</p>
                <p>제 5 조 (개인정보처리 위탁)</p>
                <p>
                  ① 에스프레스토는 원활한 개인정보 업무처리를 위하여 다음과 같이
                  개인정보 처리업무를 위탁하고 있습니다.
                </p>
                <p>
                  ② 회사는 위탁계약 체결시 개인정보 보호법 제25조에 따라
                  위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적
                  보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등
                  책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가
                  개인정보를 안전하게 처리하는지를 감독하고 있습니다.
                </p>
                <p>
                  ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본
                  개인정보 처리방침을 통하여 공개하도록 하겠습니다.
                </p>
                <p>
                  <br />제 6 조 (정보주체와 법정대리인의 이용자는
                  개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.
                </p>
                <p>
                  ① 정보주체는 회사에 대해 다음 각 호의 개인정보 보호 관련
                  권리를 행사할 수 있습니다.
                </p>
                <p>(1) 개인정보 열람요구</p>
                <p>(2) 오류 등이 있을 경우 정정 요구</p>
                <p>(3) 삭제 요구</p>
                <p>(4) 처리 정지 요구</p>
                <p>
                  ② 이용자의 개인정보를 최신의 상태로 정확하게 입력하여 불의의
                  사고를 예방해 주시기 바랍니다. 이용자가 입력한 부정확한 정보로
                  인해 발생하는 사고의 책임은 이용자 자신에게 있으며 타인 정보의
                  도용 등 허위정보를 입력할 경우 회원자격이 상실될 수 있습니다.
                </p>
                <p>
                  ③ 이용자는 개인정보를 보호받을 권리와 함께 스스로를 보호하고
                  타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 비밀번호를
                  포함한 귀하의 개인정보가 유출되지 않도록 조심하시고 게시물을
                  포함한 타인의 개인정보를 훼손하지 않도록 유의해 주십시오. 만약
                  이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할
                  시에는 『정보통신망이용촉진 및 정보보호 등에 관한 법률』,
                  『개인정보보호법』 등에 의해 처벌받을 수 있습니다. 권리·의무
                  및 그 행사방법)
                </p>
                <p>&nbsp;</p>
                <p>제 7 조(8세 이하의 아동 등의 보호의무자의권리)</p>
                <p>
                  ① 회사는 아래의 경우에 해당하는자(이하“8세 이하의 아동
                  등”이라한다)의 보호의무자가 8세 이하의 아동 등의 생명 또는
                  신체보호를 위하여 개인위치정보의 이용 또는 제공에 동의하는
                  경우에는 본인의 동의가 있는것으로 봅니다.
                </p>
                <p>1. 8세이하의아동</p>
                <p>2. 피성년후견인</p>
                <p>
                  3.『장애인복지법』 제2조제2항제2호의 규정에 의한 정신적 장애를
                  가진 자로서『장애인고용촉진및직업재활법』 제2조제2호의 규정에
                  의한 중증장애인에 해당하는자(『장애인복지법』 제32조의 규정에
                  의하여 장애인 등록을 한 자에 한한다)
                </p>
                <p>
                  ② 8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여
                  개인위치정보의 이용 또는 제공에 동의를 하고자 하는
                  보호의무자는 서면동의서에 보호의무자임을 증명하는 서면을
                  첨부하여 회사에 제출하여야 합니다.
                </p>
                <p>
                  ③ 보호의무자는 8세 이하의 아동 등의 개인위치정보의 이용 또는
                  제공에 동의하는 경우 개인위치정보주체권리의 전부를 행사할 수
                  있습니다.
                </p>
                <p>&nbsp;</p>
                <p>
                  제 8 조 (위치정보 이용ㆍ제공사실 확인자료 보유근거 및
                  보유기간)
                </p>
                <p>
                  회사는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에
                  근거하여 개인위치정보주체에 대한 위치정보 수집ㆍ이용ㆍ제공사실
                  확인자료를 위치정보시스템에 자동으로 기록하며, 6개월 이상
                  보관합니다.
                </p>
                <p>&nbsp;</p>
                <p>제 9 조 (동의 철회)</p>
                <p>
                  1. 회원가입 등을 통해 개인정보의 수집, 이용, 제공에 대해
                  회원이 동의한 내용을 회원은 언제든지 철회하실 수 있습니다.
                  동의철회는 ‘ 내정보’ {'>'} ‘설정’ {'>'} ‘회원탈퇴’를
                  클릭하거나 개인정보보호책임자에게 팩스, 우편, 고객센터, 전화
                  등으로 연락하시면 개인정보의 삭제 등 필요한 조치를 하겠습니다.
                  동의 철회를 하고 개인정보를 파기하는 등의 조치를 취한 경우에는
                  그 사실을 귀하께 지체 없이 통지하도록 하겠습니다.
                </p>
                <p>제 10 조. (개인정보의 파기)</p>
                <p>
                  에스프레스토는 원칙적으로 개인정보 처리목적이 달성된 경우에는
                  지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및
                  방법은 다음과 같습니다.
                </p>
                <p>① 파기절차</p>
                <p>
                  이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의
                  경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간
                  저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는
                  법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
                </p>
                <p>② 파기기한</p>
                <p>
                  이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는
                  보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성,
                  해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게
                  되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는
                  날로부터 5일 이내에 그 개인정보를 파기합니다.
                </p>

                <p>&nbsp;</p>
                <p>
                  제 11 조 (개인정보 자동수집 장치의 설치∙운영 및 그 거부에 관한
                  사항)
                </p>
                <p>
                  ① 에스프레스토는 개별적인 맞춤서비스를 제공하기 위해
                  이용정보를 저장하고 수시로 불러오는 ‘쿠기(cookie)’를
                  사용합니다.
                </p>
                <p>
                  ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의
                  컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC
                  컴퓨터내의 하드디스크에 저장되기도 합니다.
                </p>
                <p>
                  (1) 쿠키의 사용 목적: 이용자가 방문한 각 서비스와 웹
                  사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부,
                  등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
                </p>
                <p>
                  (2) 쿠키의 설치•운영 및 거부: 웹브라우저 상단의 도구&gt;인터넷
                  옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수
                  있습니다.
                </p>
                <p>
                  (3) 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이
                  발생할 수 있습니다.
                </p>
                <p>&nbsp;</p>
                <p>제 12 조 (링크사이트 제공)</p>
                <p>
                  회사는 이용자들께 귀하께 다른 회사의 웹사이트 또는 자료에 대한
                  링크를 제공할 수 있습니다. 이 경우 회사는 외부사이트 및 자료에
                  대한 아무런 통제권이 없으므로 그로부터 제공받는 서비스나
                  자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다.
                  회사가 포함하고 있는 링크를 클릭(click)하여 타 사이트(site)의
                  페이지로 옮겨갈 경우 해당 사이트의 개인정보처리방침은 회사와
                  무관하므로 새로 방문한 사이트의 정책을 검토해 보시기 바랍니다.
                </p>
                <p>&nbsp;</p>
                <p>제 13 조 (맞춤형 광고 안내)</p>
                <p>
                  에스프레스토는 이용자의 웹사이트 방문 기록, 앱 사용 기록, 검색
                  기록 등의 행태정보를 기반으로 이용자께 최적화된 맞춤형 서비스
                  및 혜택을 제공해드리기 위해 노력하고 있습니다.
                </p>
                <p>
                  이용자께 무의미하고 불필요한 광고 노출을 지양하고, 이용자의
                  관심 및 흥미 등을 반영한 정보성 광고 노출을 증가시키기 위하여
                  활용 방송통신위원회의 ‘온라인 맞춤형 광고 개인정보보호
                  가이드라인’ 준수하고자 본 맞춤형 광고 안내를 드립니다.
                </p>
                <p>
                  방송통신위원회 가이드라인과 본 안내에서 사용된 용어의 정의는
                  아래와 같습니다.
                </p>
                <p>
                  - 온라인 행태정보: 웹 사이트 방문이력, 앱 사용 이력, 구매 및
                  검색 이력 등 이용자의 관심, 흥미, 기호 및 성향 등을 파악하고
                  분석할 수 있는 온라인상의 이용자 활동정보
                </p>
                <p>
                  - 온라인 맞춤형 광고: 행태정보를 처리하여 이용자의 관심, 흥미,
                  기호 및 성향 등을 분석/추정한 후 이용자에게 맞춤형으로
                  제공되는 온라인 광고
                </p>
                <p>① 행태정보 수집ㆍ이용 안내</p>
                <p>
                  위 행태정보는 서비스 이용 과정에서 자동으로 수집 및
                  저장되며,개인 식별이 불가능한 상태에서 활용하는 등 개인정보와
                  결합하여 사용하지 않습니다.
                </p>
                <p>
                  행태정보 수집은 서비스 이용 과정에서 자동 생성되어 저장되는
                  형태입니다.
                </p>
                <p>
                  아울러 당사가 수집하는 행태정보 외에 제휴사(광고사업자)에 의해
                  이용자의 웹사이트 방문기록, 검색 기록, 앱 사용 기록 등이
                  수집되어 질 수 있으며, 운영현황은 다음과 같습니다.
                </p>
                <table>
                  <tbody>
                    <tr>
                      <td width="120">
                        <p>행태정보의 수집ㆍ처리 광고 사업자</p>
                      </td>
                      <td width="163">
                        <p>수집하는 행태정보 항목</p>
                      </td>
                      <td width="99">
                        <p>행태정보 수집 방법</p>
                      </td>
                      <td width="99">
                        <p>행태정보 수집 목적</p>
                      </td>
                      <td width="120">
                        <p>행태정보 보유ㆍ이용 기간 및 이후 정보처리 방법</p>
                      </td>
                    </tr>
                    <tr>
                      <td width="120">
                        <p>에스프레스토(주)</p>
                      </td>
                      <td width="163">
                        <p>
                          이용자의 웹사이트 방문이력, 이용자의 앱 사용 이력,
                          이용자의 검색 이력, 광고식별자
                        </p>
                      </td>
                      <td width="99">
                        <p>이용자의 웹/앱 사이트 방문/실행 시 자동 수집</p>
                      </td>
                      <td width="99">
                        <p>사용자 관심 기반의 맞춤형 광고 제공</p>
                      </td>
                      <td width="120">
                        <p>웹/앱 사이트 방문 정보 2년, 상품검색 정보 2년</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>② 행태정보 제공 안내</p>
                <p>
                  회사는 이용자의 비식별 광고ID 기반의 웹사이트 방문기록, 검색
                  기록, 앱 사용 기록 등의 행태정보를 제휴사(광고사업자)에 제공할
                  수 있으며, 제공자가 생길 경우 공지사항 또는 수정 약관에 운영
                  현황에 명시합니다.
                </p>
                <p>&nbsp;</p>
                <p>③ 고객(이용자) 통제권 행사 방법</p>
                <p>
                  회사의 온라인 맞춤형 광고는 고객에게 적절한 광고를 제공하기
                  위해 모바일 앱에서 광고 식별자(Advertiser id)를 사용합니다.
                </p>
                <p>
                  광고 식별자는 모바일 운영체제에서 발급하는 ID로 맞춤형 정보나
                  광고를 제공하기 위해 사용되는 식별자입니다. 이용자는 맞춤형
                  광고 수신 여부를 선택할 수 있으며, 아래의 방법으로 언제든지 각
                  OS에서 맞춤형 광고 설정을 해제할 수 있습니다.
                </p>
                <p>
                  (1) 안드로이드 ① 구글 설정 → ② 광고 → ③ 광고 맞춤설정 선택
                  해제 ON/OFF
                </p>
                <p>
                  (2) iOS ① 아이폰 설정 → ② 개인정보보호 → ③ 광고 → ④ 광고 추적
                  제한 ON/OFF
                </p>
                <p>&nbsp;</p>
                <p>제 14 조 (개인정보 보호책임자)</p>
                <p>
                  ① 에스프레스토는 개인정보 처리에 관한 업무를 총괄해서
                  책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및
                  피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를
                  지정하고 있습니다.
                </p>
                <p>▶ 개인정보보호책임자</p>
                <p>성명: 박성환</p>
                <p>
                  연락처:
                  <a href="mailto:010-3273-8190/dhson@spresto.net">
                    010-9548-2253/shpark@spresto.net
                  </a>
                </p>
                <p>
                  ② 정보주체는 회사의 서비스(또는 사업)을 이용하시면서 발생한
                  모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한
                  사항을 개인정보 보호책임자에게 문의하실 수 있습니다.
                </p>
                <p>&nbsp;</p>
                <p>제 15 조 (개인정보의 안정성 확보 조치)</p>
                <p>
                  에스프레스토는 개인정보보호법 제29조에 따라 다음과 같이 안전성
                  확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.
                </p>
                <p>① 정기적인 자체 감사 실시</p>
                <p>
                  개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로
                  자체 감사를 실시하고 있습니다.
                </p>
                <p>② 개인정보 취급 직원의 최소화 및 교육</p>
                <p>
                  개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화
                  하여 개인정보를 관리하는 대책을 시행하고 있습니다.
                </p>
                <p>③ 내부관리계획의 수립 및 시행</p>
                <p>
                  개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고
                  시행하고 있습니다.
                </p>
                <p>④ 해킹 등에 대비한 기술적 대책</p>
                <p>
                  해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을
                  막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며
                  외부로부터 접근이 통제된 구역에 시스템을 설치하고
                  기술적/물리적으로 감시 및 차단하고 있습니다.
                </p>
                <p>⑤ 개인정보의 암호화</p>
                <p>
                  이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고
                  있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송
                  데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도
                  보안기능을 사용하고 있습니다.
                </p>
                <p>⑥ 접속기록의 보관 및 위변조 방지</p>
                <p>
                  개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관,
                  관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록
                  보안기능 사용하고 있습니다.
                </p>
                <p>⑦ 개인정보에 대한 접근 제한</p>
                <p>
                  개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의
                  부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여
                  필요한 조치를 하고 있으며 침입차단시스템을 이용하여
                  외부로부터의 무단 접근을 통제하고 있습니다.
                </p>
                <p>⑧ 문서보안을 위한 잠금장치 사용</p>
                <p>
                  개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는
                  안전한 장소에 보관하고 있습니다.
                </p>
                <p>⑨ 비인가자에 대한 출입 통제</p>
                <p>
                  개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에
                  대해 출입통제 절차를 수립, 운영하고 있습니다.
                </p>
                <p>&nbsp;</p>
                <p>제 16 조 (개인정보 열람 및 정정)</p>
                <p>
                  ① 이용자는 언제든지 등록되어 있는 본인의 개인정보를 열람하거나
                  정정하실 수 있습니다. 개인정보 열람 및 정정을 하고자 할
                  경우에는 『개인정보수정』을 클릭하여 직접 열람 또는
                  정정하거나, 개인정보보호책임자 및 담당자에게 서면, 전화 또는
                  E-mail로 연락하시면 지체 없이 조치하겠습니다.
                </p>
                <p>
                  ② 이용자가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을
                  완료하기 전까지 당해 개인 정보를 이용 또는 제공하지 않습니다.
                </p>
                <p>
                  ③ 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정
                  처리결과를 제3자에게 지체 없이 통지하여 정정하도록
                  조치하겠습니다.
                </p>
                <p>&nbsp;</p>
                <p>제 17 조 (정보주체아래의 의 권익침해에 대한 구제방법)</p>
                <p>
                  기관은 에스프레스토㈜와는 별개의 기관으로서, 회사의 자체적인
                  개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다
                  자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
                </p>
                <p>&nbsp;</p>
                <p>▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)</p>
                <p>- 소관업무 : 개인정보 침해사실 신고, 상담 신청</p>
                <p>- 홈페이지 : privacy.kisa.or.kr</p>
                <p>- 전화 : (국번없이) 118</p>
                <p>
                  - 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층
                  개인정보침해신고센터
                </p>
                <p>&nbsp;</p>
                <p>▶ 개인정보 분쟁조정위원회</p>
                <p>
                  - 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
                </p>
                <p>- 홈페이지 : www.kopico.go.kr</p>
                <p>- 전화 : (국번없이) 1833-6972</p>
                <p>
                  - 주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사
                  4층
                </p>
                <p>&nbsp;</p>
                <p>
                  ▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)
                </p>
                <p>
                  ▶ 경찰청 사이버안전국 : 182 (
                  <a href="http://cyberbureau.police.go.kr">
                    http://cyberbureau.police.go.kr
                  </a>
                  )
                </p>
                <p>&nbsp;</p>
                <p>제 18 조 (개인정보 처리방침의 개정과 그 공지)</p>
                <p>
                  법률이나 서비스의 변경사항을 반영하기 위한 목적 등으로
                  개인정보처리방침을 수정할 수 있습니다. 개인정보처리방침이
                  변경되는 경우 에스프레스토는 변경 사항을 게시하며, 변경된
                  개인정보처리방침은 게시한 날로부터 7일 후부터 효력이
                  발생합니다.
                </p>
                <p>
                  다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이
                  이용자 권리의 중대한 변경이 발생할 때에는 최소 30일 전에 미리
                  알려드리겠습니다.
                </p>
                <p>공지일자 : 2024년 9월 25일</p>
                <p>시행일자 : 2024년 10월 25일</p>
                <p>&nbsp;</p>
                <p>
                  에스프레스토(주)는 이용자 여러분의 정보를 소중히 생각하며,
                  이용자가 더욱 안심하고 서비스를 이용할 수 있도록 최선의 노력을
                  다할것을 약속드립니다.
                </p>
              </article>
            </div>
          </div>
        </div>
      </div>
    </ContactWrapper>
  )
}

const ContactWrapper = styled.section`
  position: relative;
  min-width: 1440px;
  padding: 0 60px 0 60px;
  background-color: ${(props) => props.theme.color.WPrimary};
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 100px;

  @media all and (max-width: 768px) {
    word-break: keep-all;
    min-width: 100%;
    width: 100%;
    margin-top: 3.5rem;
    padding: 0 1.5rem;
  }
`

const Title = styled.div`
  font-size: 36px;
  font-weight: 700;
  margin-top: 50px;

  @media all and (max-width: 768px) {
    min-width: 100%;
    margin-top: 3.5rem;
    line-height: 3.2rem;
    padding: 0 1.5rem;
  }
`

const SubTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-top: 60px;

  @media all and (max-width: 768px) {
    min-width: 100%;
    margin-top: 3.5rem;
    padding: 0 1.5rem;
  }
`

const Description = styled.div`
  font-size: 18px;
  font-weight: 400;
  margin-top: 20px;

  @media all and (max-width: 768px) {
    min-width: 100%;
    margin-top: 0.5rem;
    /* margin-bottom: 1rem; */
    line-height: 2rem;
    padding: 0 1.5rem;
  }
`

const SubDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  @media all and (max-width: 768px) {
    min-width: 100%;
    line-height: 1.5rem;
    padding: 0 1.5rem;
  }
`

const ImgWrapper = styled.div`
  @media all and (max-width: 768px) {
    img {
      margin-top: 3rem;
      width: 21rem;
    }
  }
`
const BtnBox = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  button {
    width: 216px;
    height: 53px;
    border: 1.5px solid ${(props) => props.theme.color.GPrimary};
    border-radius: 10px;
    cursor: pointer;
  }
  @media all and (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    position: static;
    margin-bottom: 2.5rem;
    button {
      width: 10.5rem;
      background-size: 7.5rem;
      height: 3rem;
      margin-right: 0.3rem;
    }
  }
`

const AppStoreBtn = styled.button`
  /* margin-right: 3rem; */
  background: url('/assets/icons/appstore.png') no-repeat center;
`
const GooglePlayBtn = styled.button`
  background: url('/assets/icons/googleplay.png') no-repeat center;
`

export default Terms
