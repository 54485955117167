import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const Terms5 = () => {
  const [data, setData] = useState('')

  useEffect(() => {
    fetch('/data/Terms/terms5.txt')
      .then((res) => res.text())
      .then((data) => {
        setData(data)
      })
  }, [])
  return (
    <ContactWrapper>
      <div id="wrapper">
        <div class="contents" id="fullpage" data-ui-parellax="wrapper">
          <div class="contents--outer-wrap section">
            <div class="contents-wrapper">
              <article>{data}</article>
            </div>
          </div>
        </div>
      </div>
    </ContactWrapper>
  )
}

const ContactWrapper = styled.section`
  position: relative;
  min-width: 1440px;
  padding: 0 60px 0 60px;
  background-color: ${(props) => props.theme.color.WPrimary};
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 100px;

  article {
    white-space: pre-line;
    line-height: 2rem;
    padding: 2rem 0;
  }

  @media all and (max-width: 768px) {
    word-break: keep-all;
    min-width: 100%;
    width: 100%;
    margin-top: 3.5rem;
    padding: 0 1.5rem;
  }
`

export default Terms5
