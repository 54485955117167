import styled from 'styled-components'

function Terms() {
  return (
    <ContactWrapper>
      <div id="wrapper">
        <div class="contents" id="fullpage" data-ui-parellax="wrapper">
          <div class="contents--outer-wrap section">
            <div class="contents-wrapper">
              <article style={{ lineHeight: '2rem', paddingTop: '2rem' }}>
                <p class="description">
                  <p>
                    <b>위치기반서비스 이용약관</b>
                  </p>
                  <p>
                    <span>&nbsp;</span>
                  </p>
                  <span>
                    제&nbsp;1 조&nbsp;(목적)
                    <br />
                  </span>
                  <span>
                    에스프레스토
                    주식회사(이하&nbsp;“회사”라고&nbsp;합니다.)가&nbsp;제공하는&nbsp;지도
                    기반 몰카 탐지
                    소셜네트워크&nbsp;서비스(이하&nbsp;“서비스”라고&nbsp;합니다)를&nbsp;이용함에&nbsp;있어&nbsp;회사와&nbsp;회원간의&nbsp;권리·의무&nbsp;및&nbsp;책임사항을&nbsp;규정함을&nbsp;목적으로&nbsp;합니다.
                    <br />
                  </span>
                  <span>
                    제&nbsp;2 조&nbsp;(약관의&nbsp;명시, 효력&nbsp;및&nbsp;개정)
                    <br />
                  </span>
                  <span>
                    ①
                    본&nbsp;약관은&nbsp;서비스를&nbsp;신청한&nbsp;고객&nbsp;또는&nbsp;개인위치정보주체가&nbsp;본&nbsp;약관에&nbsp;동의하고&nbsp;회사가&nbsp;정한&nbsp;소정의&nbsp;절차에&nbsp;따라&nbsp;서비스의&nbsp;이용자로&nbsp;등록함으로써&nbsp;효력이&nbsp;발생합니다.
                    <br />
                  </span>
                  <span>
                    ② 회원이&nbsp;온라인에서&nbsp;본&nbsp;약관의&nbsp;“동의하기”
                    버튼을&nbsp;클릭하였을&nbsp;경우&nbsp;본&nbsp;약관의&nbsp;내용을&nbsp;모두&nbsp;읽고&nbsp;이를&nbsp;충분히&nbsp;이해하였으며,
                    그&nbsp;적용에&nbsp;동의한&nbsp;것으로&nbsp;봅니다.
                    <br />
                  </span>
                  <span>
                    ③
                    회사는&nbsp;위치정보의&nbsp;보호&nbsp;및&nbsp;이용&nbsp;등에&nbsp;관한&nbsp;법률,
                    콘텐츠산업&nbsp;진흥법,
                    전자상거래&nbsp;등에서의&nbsp;소비자보호에&nbsp;관한&nbsp;법률,
                    소비자&nbsp;기본법&nbsp;약관의&nbsp;규제에&nbsp;관한&nbsp;법률&nbsp;등&nbsp;관련법령을&nbsp;위배하지&nbsp;않는&nbsp;범위에서&nbsp;본&nbsp;약관을&nbsp;개정할&nbsp;수&nbsp;있습니다.
                    <br />
                  </span>
                  <span>
                    ④
                    회사가&nbsp;약관을&nbsp;개정할&nbsp;경우에는&nbsp;기존약관과&nbsp;개정약관&nbsp;및&nbsp;개정약관의&nbsp;적용일자와&nbsp;개정사유를&nbsp;명시하여&nbsp;현행약관과&nbsp;함께&nbsp;그&nbsp;적용일자&nbsp;10일&nbsp;전부터&nbsp;적용일&nbsp;이후&nbsp;상당한&nbsp;기간&nbsp;동안&nbsp;공지만을&nbsp;하고,
                    개정&nbsp;내용이&nbsp;회원에게&nbsp;불리한&nbsp;경우에는&nbsp;그&nbsp;적용일자&nbsp;30일&nbsp;전부터&nbsp;적용일&nbsp;이후&nbsp;상당한&nbsp;기간&nbsp;동안&nbsp;각각&nbsp;이를&nbsp;서비스&nbsp;홈페이지에&nbsp;게시하거나&nbsp;회원에게&nbsp;전자적&nbsp;형태(전자우편,
                    SMS, 공지사항
                    등)로&nbsp;약관&nbsp;개정&nbsp;사실을&nbsp;발송하여&nbsp;고지합니다.
                    <br />
                  </span>
                  <span>
                    ⑤
                    회사가&nbsp;전항에&nbsp;따라&nbsp;회원에게&nbsp;통지하면서&nbsp;공지&nbsp;또는&nbsp;공지ㆍ고지일로부터&nbsp;개정약관&nbsp;시행일&nbsp;7일&nbsp;후까지&nbsp;거부의사를&nbsp;표시하지&nbsp;아니하면&nbsp;이용약관에&nbsp;승인한&nbsp;것으로&nbsp;봅니다.
                    회원이&nbsp;개정약관에&nbsp;동의하지&nbsp;않을&nbsp;경우&nbsp;회원은&nbsp;이용계약을&nbsp;해지할&nbsp;수&nbsp;있습니다.
                    <br />
                  </span>
                  <span>
                    제&nbsp;3 조&nbsp;(약관&nbsp;외&nbsp;준칙)
                    <br />
                  </span>
                  <span>
                    ①
                    본&nbsp;약관은&nbsp;위치정보보호법&nbsp;등의&nbsp;대한민국법령에&nbsp;의하여&nbsp;규정되고&nbsp;이행됩니다.
                    <br />
                  </span>
                  <span>
                    ②
                    본&nbsp;약관과&nbsp;서비스&nbsp;약관의&nbsp;내용이&nbsp;충돌하는&nbsp;경우&nbsp;본&nbsp;약관의&nbsp;내용이&nbsp;우선하며&nbsp;본&nbsp;약관에&nbsp;명시되지&nbsp;않은&nbsp;사항에&nbsp;대해서는&nbsp;회사가&nbsp;정한&nbsp;서비스&nbsp;약관&nbsp;등에&nbsp;따르며,
                    서비스&nbsp;약관&nbsp;등에&nbsp;규정되지&nbsp;않은&nbsp;사항은&nbsp;관련법령&nbsp;및&nbsp;상관습에&nbsp;의합니다.
                    <br />
                  </span>
                  <span>
                    제&nbsp;4 조&nbsp;(서비스&nbsp;이용요금)
                    <br />
                  </span>
                  <span>
                    ①
                    회사가&nbsp;제공하는&nbsp;서비스는&nbsp;기본적으로&nbsp;유료&nbsp;또는&nbsp;무료입니다.
                    단,
                    별도의&nbsp;유료&nbsp;서비스의&nbsp;경우&nbsp;해당&nbsp;서비스에&nbsp;명시된&nbsp;요금을&nbsp;지불하여야&nbsp;사용&nbsp;가능합니다.
                    <br />
                  </span>
                  <span>
                    ②
                    회사는&nbsp;유료&nbsp;서비스&nbsp;이용요금을&nbsp;회사와&nbsp;계약한&nbsp;전자지불업체에서&nbsp;정한&nbsp;방법에&nbsp;의하거나&nbsp;회사가&nbsp;정한&nbsp;청구서에&nbsp;합산하여&nbsp;청구할&nbsp;수&nbsp;있습니다.
                    <br />
                  </span>
                  <span>
                    ③
                    유료서비스&nbsp;이용을&nbsp;통하여&nbsp;결제된&nbsp;대금에&nbsp;대한&nbsp;취소&nbsp;및&nbsp;환불은&nbsp;회사의&nbsp;결제&nbsp;이용약관&nbsp;등&nbsp;관계법에&nbsp;따릅니다.
                    <br />
                  </span>
                  <span>
                    ④
                    회원의&nbsp;개인정보도용&nbsp;및&nbsp;결제사기로&nbsp;인한&nbsp;환불요청&nbsp;또는&nbsp;결제자의&nbsp;개인정보&nbsp;요구는&nbsp;법률이&nbsp;정한&nbsp;경우&nbsp;외에는&nbsp;거절될&nbsp;수&nbsp;있습니다.
                    <br />
                  </span>
                  <span>
                    ⑤
                    무선&nbsp;서비스&nbsp;이용&nbsp;시&nbsp;발생하는&nbsp;데이터&nbsp;통신료는&nbsp;별도이며&nbsp;가입한&nbsp;각&nbsp;이동통신사의&nbsp;정책에&nbsp;따릅니다.
                    <br />
                  </span>
                  <span>
                    ⑥ MMS
                    등으로&nbsp;게시물을&nbsp;등록할&nbsp;경우&nbsp;발생하는&nbsp;요금은&nbsp;이동통신사의&nbsp;정책에&nbsp;따릅니다.
                    <br />
                  </span>
                  <span>
                    제&nbsp;5 조&nbsp;(서비스내용변경&nbsp;통지&nbsp;등)
                    <br />
                  </span>
                  <span>
                    ①
                    회사가&nbsp;서비스&nbsp;내용을&nbsp;변경하거나&nbsp;종료하는&nbsp;경우&nbsp;회사는&nbsp;회원의&nbsp;등록된&nbsp;전자우편&nbsp;주소로&nbsp;이메일을&nbsp;통하여&nbsp;서비스&nbsp;내용의&nbsp;변경&nbsp;사항&nbsp;또는&nbsp;종료를&nbsp;통지할&nbsp;수&nbsp;있습니다.
                    <br />
                  </span>
                  <span>
                    ②
                    제1항의&nbsp;경우&nbsp;불특정&nbsp;다수인을&nbsp;상대로&nbsp;통지를&nbsp;함에&nbsp;있어서는&nbsp;웹사이트&nbsp;등&nbsp;기타&nbsp;회사의&nbsp;공지사항을&nbsp;통하여&nbsp;회원들에게&nbsp;통지할&nbsp;수&nbsp;있습니다.
                    <br />
                  </span>
                  <span>
                    제&nbsp;6 조&nbsp;(개인위치정보주체의&nbsp;권리)
                    <br />
                  </span>
                  <span>
                    ①
                    회원은&nbsp;회사에&nbsp;대하여&nbsp;언제든지&nbsp;개인위치정보의&nbsp;수집에&nbsp;대한&nbsp;동의&nbsp;또는&nbsp;개인위치정보를&nbsp;이용한&nbsp;위치기반서비스&nbsp;제공&nbsp;및&nbsp;개인위치정보의&nbsp;제3자&nbsp;제공에&nbsp;대한&nbsp;동의의&nbsp;전부&nbsp;또는&nbsp;일부를&nbsp;철회할&nbsp;수&nbsp;있습니다.
                    이&nbsp;경우&nbsp;회사는&nbsp;수집한&nbsp;개인위치정보&nbsp;및&nbsp;위치정보&nbsp;수집,
                    이용,
                    제공사실&nbsp;확인자료(동의의&nbsp;일부를&nbsp;철회한&nbsp;경우에는&nbsp;철회한&nbsp;부분의&nbsp;개인위치정보&nbsp;및&nbsp;위치정보&nbsp;이용,
                    제공사실&nbsp;확인자료에&nbsp;한함)를&nbsp;파기합니다.
                    <br />
                  </span>
                  <span>
                    ②
                    회원은&nbsp;회사에&nbsp;대하여&nbsp;언제든지&nbsp;개인위치정보의&nbsp;수집,
                    이용&nbsp;또는&nbsp;제공의&nbsp;일시적인&nbsp;중지를&nbsp;요구할&nbsp;수&nbsp;있으며,
                    회사는&nbsp;이를&nbsp;거절할&nbsp;수&nbsp;없고&nbsp;이를&nbsp;위한&nbsp;기술적&nbsp;수단을&nbsp;갖추고&nbsp;있습니다.
                    <br />
                  </span>
                  <span>
                    ③
                    회원은&nbsp;회사에&nbsp;대하여&nbsp;아래&nbsp;각&nbsp;호의&nbsp;자료에&nbsp;대한&nbsp;열람&nbsp;또는&nbsp;고지를&nbsp;요구할&nbsp;수&nbsp;있고,
                    당해&nbsp;자료에&nbsp;오류가&nbsp;있는&nbsp;경우에는&nbsp;그&nbsp;정정을&nbsp;요구할&nbsp;수&nbsp;있습니다.
                    이&nbsp;경우&nbsp;회사는&nbsp;정당한&nbsp;사유&nbsp;없이&nbsp;회원의&nbsp;요구를&nbsp;거절할&nbsp;수&nbsp;없습니다.
                    <br />
                  </span>
                  <span>
                    1. 본인에&nbsp;대한&nbsp;위치정보&nbsp;수집, 이용,
                    제공사실&nbsp;확인자료
                    <br />
                  </span>
                  <span>
                    2.
                    본인의&nbsp;개인위치정보가&nbsp;위치정보의&nbsp;보호&nbsp;및&nbsp;이용&nbsp;등에&nbsp;관한&nbsp;법률&nbsp;또는&nbsp;다른&nbsp;법률&nbsp;규정에&nbsp;의하여&nbsp;제3자에게&nbsp;제공된&nbsp;이유&nbsp;및&nbsp;내용
                    <br />
                  </span>
                  <span>
                    ④
                    회원은&nbsp;제1호&nbsp;내지&nbsp;제3호의&nbsp;권리행사를&nbsp;위해&nbsp;회사의&nbsp;소정의&nbsp;절차를&nbsp;통해&nbsp;요구할&nbsp;수&nbsp;있습니다.
                    <br />
                  </span>
                  <span>
                    제&nbsp;7 조&nbsp;(법정대리인의&nbsp;권리)
                    <br />
                  </span>
                  <span>
                    ①
                    회사는&nbsp;14세&nbsp;미만의&nbsp;회원에&nbsp;대해서는&nbsp;개인위치정보를&nbsp;이용한&nbsp;위치기반서비스&nbsp;제공&nbsp;및&nbsp;개인위치정보의&nbsp;제3자&nbsp;제공에&nbsp;대한&nbsp;동의를&nbsp;당해&nbsp;회원과&nbsp;당해&nbsp;회원의&nbsp;법정대리인으로부터&nbsp;동의를&nbsp;받아야&nbsp;합니다.
                    이&nbsp;경우&nbsp;법정대리인은&nbsp;제7조에&nbsp;의한&nbsp;회원의&nbsp;권리를&nbsp;모두&nbsp;가집니다.
                    <br />
                  </span>
                  <span>
                    ②
                    회사는&nbsp;14세&nbsp;미만의&nbsp;아동의&nbsp;개인위치정보&nbsp;또는&nbsp;위치정보&nbsp;이용/제공사실&nbsp;확인자료를&nbsp;이용약관에&nbsp;명시&nbsp;또는&nbsp;고지한&nbsp;범위를&nbsp;넘어&nbsp;이용하거나&nbsp;제3자에게&nbsp;제공하고자&nbsp;하는&nbsp;경우에는&nbsp;14세미만의&nbsp;아동과&nbsp;그&nbsp;법정대리인의&nbsp;동의를&nbsp;받아야&nbsp;합니다.
                    단, 아래의&nbsp;경우는&nbsp;제외합니다.
                    <br />
                  </span>
                  <span>
                    1.
                    위치정보&nbsp;및&nbsp;위치기반서비스&nbsp;제공에&nbsp;따른&nbsp;요금정산을&nbsp;위하여&nbsp;위치정보&nbsp;이용,
                    제공사실&nbsp;확인자료가&nbsp;필요한&nbsp;경우
                    <br />
                  </span>
                  <span>
                    2. 통계작성,
                    학술연구&nbsp;또는&nbsp;시장조사를&nbsp;위하여&nbsp;특정&nbsp;개인을&nbsp;알아볼&nbsp;수&nbsp;없는&nbsp;형태로&nbsp;가공하여&nbsp;제공하는&nbsp;경우
                    <br />
                  </span>
                  <span>
                    제&nbsp;8
                    조&nbsp;(8세&nbsp;이하의&nbsp;아동&nbsp;등의&nbsp;보호의무자의&nbsp;권리)
                    <br />
                  </span>
                  <span>
                    ①
                    회사는&nbsp;아래의&nbsp;경우에&nbsp;해당하는&nbsp;자(이하&nbsp;“8세&nbsp;이하의&nbsp;아동”등이라&nbsp;한다)의&nbsp;보호의무자가&nbsp;8세&nbsp;이하의&nbsp;아동&nbsp;등의&nbsp;생명&nbsp;또는&nbsp;신체보호를&nbsp;위하여&nbsp;개인위치정보의&nbsp;이용&nbsp;또는&nbsp;제공에&nbsp;동의하는&nbsp;경우에는&nbsp;본인의&nbsp;동의가&nbsp;있는&nbsp;것으로&nbsp;봅니다.
                    <br />
                  </span>
                  <span>
                    1. 8세&nbsp;이하의&nbsp;아동
                    <br />
                  </span>
                  <span>
                    2. 금치산자
                    <br />
                  </span>
                  <span>
                    3.
                    장애인복지법제2조제2항제2호의&nbsp;규정에&nbsp;의한&nbsp;정신적&nbsp;장애를&nbsp;가진&nbsp;자로서장애인고용촉진및직업재활법&nbsp;제2조제2호의&nbsp;규정에&nbsp;의한&nbsp;중증장애인에&nbsp;해당하는&nbsp;자(장애인복지법&nbsp;제29조의&nbsp;규정에&nbsp;의하여&nbsp;장애인등록을&nbsp;한&nbsp;자에&nbsp;한한다)
                    <br />
                  </span>
                  <span>
                    ②
                    8세&nbsp;이하의&nbsp;아동&nbsp;등의&nbsp;생명&nbsp;또는&nbsp;신체의&nbsp;보호를&nbsp;위하여&nbsp;개인위치정보의&nbsp;이용&nbsp;또는&nbsp;제공에&nbsp;동의를&nbsp;하고자&nbsp;하는&nbsp;보호의무자는&nbsp;서면동의서에&nbsp;보호의무자임을&nbsp;증명하는&nbsp;서면을&nbsp;첨부하여&nbsp;회사에&nbsp;제출하여야&nbsp;합니다.
                    <br />
                  </span>
                  <span>
                    ③
                    보호의무자는&nbsp;8세&nbsp;이하의&nbsp;아동&nbsp;등의&nbsp;개인위치정보&nbsp;이용&nbsp;또는&nbsp;제공에&nbsp;동의하는&nbsp;경우&nbsp;개인위치정보주체&nbsp;권리의&nbsp;전부를&nbsp;행사할&nbsp;수&nbsp;있습니다.
                    <br />
                  </span>
                  <span>
                    제&nbsp;9
                    조&nbsp;(개인위치정보의&nbsp;이용&nbsp;또는&nbsp;제공)
                    <br />
                  </span>
                  <span>
                    ①
                    회사는&nbsp;개인위치정보를&nbsp;이용하여&nbsp;서비스를&nbsp;제공하고자&nbsp;하는&nbsp;경우에는&nbsp;미리&nbsp;이용약관에&nbsp;명시한&nbsp;후&nbsp;개인위치정보주체의&nbsp;동의를&nbsp;얻어야&nbsp;합니다.
                    <br />
                  </span>
                  <span>
                    ②
                    회사는&nbsp;타사업자&nbsp;또는&nbsp;이용&nbsp;고객과의&nbsp;요금정산&nbsp;및&nbsp;민원처리를&nbsp;위해&nbsp;위치정보&nbsp;이용•제공&nbsp;사실&nbsp;확인자료를&nbsp;자동&nbsp;기록•보존하며,
                    해당&nbsp;자료는&nbsp;1년간&nbsp;보관합니다.
                    <br />
                  </span>
                  <span>
                    ③
                    회사는&nbsp;개인위치정보를&nbsp;회원이&nbsp;지정하는&nbsp;제3자&nbsp;및&nbsp;차량&nbsp;동승&nbsp;서비스를&nbsp;신청하여&nbsp;검색된&nbsp;제3자에게&nbsp;제공하는&nbsp;경우에는&nbsp;개인위치정보를&nbsp;수집한&nbsp;당해&nbsp;통신&nbsp;단말장치로&nbsp;매회&nbsp;회원에게&nbsp;제공받는&nbsp;자,
                    제공일시&nbsp;및&nbsp;제공목적을&nbsp;즉시&nbsp;통보합니다.
                    단,
                    아래&nbsp;각&nbsp;호의&nbsp;1에&nbsp;해당하는&nbsp;경우에는&nbsp;회원이&nbsp;미리&nbsp;특정하여&nbsp;지정한&nbsp;통신&nbsp;단말장치&nbsp;또는&nbsp;전자우편주소로&nbsp;통보합니다.
                    <br />
                  </span>
                  <span>
                    1.
                    개인위치정보를&nbsp;수집한&nbsp;당해&nbsp;통신단말장치가&nbsp;문자,
                    음성&nbsp;또는&nbsp;영상의&nbsp;수신기능을&nbsp;갖추지&nbsp;아니한&nbsp;경우
                    <br />
                  </span>
                  <span>
                    2.
                    회원이&nbsp;개인위치정보를&nbsp;수집한&nbsp;당해&nbsp;통신단말장치&nbsp;외의&nbsp;통신단말장치&nbsp;또는&nbsp;전자우편주소&nbsp;등으로&nbsp;통보할&nbsp;것을&nbsp;미리&nbsp;요청한&nbsp;경우
                    <br />
                  </span>
                  <span>
                    제&nbsp;10 조&nbsp;(서비스내용변경&nbsp;통지&nbsp;등)
                    <br />
                  </span>
                  <span>
                    회사가&nbsp;서비스&nbsp;내용을&nbsp;변경하거나&nbsp;종료하는&nbsp;경우&nbsp;서비스&nbsp;내&nbsp;“공지사항”
                    화면을&nbsp;통해&nbsp;공고할&nbsp;수&nbsp;있습니다.
                    <br />
                  </span>
                  <span>
                    제&nbsp;11
                    조&nbsp;(서비스이용의&nbsp;제한&nbsp;및&nbsp;중지)
                    <br />
                  </span>
                  <span>
                    ①
                    회사는&nbsp;아래&nbsp;각&nbsp;호의&nbsp;1에&nbsp;해당하는&nbsp;사유가&nbsp;발생한&nbsp;경우에는&nbsp;회원의&nbsp;서비스&nbsp;이용을&nbsp;제한하거나&nbsp;중지시킬&nbsp;수&nbsp;있습니다.
                    <br />
                  </span>
                  <span>
                    1.
                    회원이&nbsp;회사&nbsp;서비스의&nbsp;운영을&nbsp;고의&nbsp;또는&nbsp;중과실로&nbsp;방해하는&nbsp;경우
                    <br />
                  </span>
                  <span>
                    2. 서비스용&nbsp;설비&nbsp;점검,
                    보수&nbsp;또는&nbsp;공사로&nbsp;인하여&nbsp;부득이한&nbsp;경우
                    <br />
                  </span>
                  <span>
                    3.
                    전기통신사업법에&nbsp;규정된&nbsp;기간통신사업자가&nbsp;전기통신&nbsp;서비스를&nbsp;중지했을&nbsp;경우
                    <br />
                  </span>
                  <span>
                    4. 국가비상사태,
                    서비스&nbsp;설비의&nbsp;장애&nbsp;또는&nbsp;서비스&nbsp;이용의&nbsp;폭주&nbsp;등으로&nbsp;서비스&nbsp;이용에&nbsp;지장이&nbsp;있는&nbsp;때
                    <br />
                  </span>
                  <span>
                    5.
                    기타&nbsp;중대한&nbsp;사유로&nbsp;인하여&nbsp;회사가&nbsp;서비스&nbsp;제공을&nbsp;지속하는&nbsp;것이&nbsp;부적당하다고&nbsp;인정하는&nbsp;경우
                    <br />
                  </span>
                  <span>
                    ②
                    회사는&nbsp;전항의&nbsp;규정에&nbsp;의하여&nbsp;서비스의&nbsp;이용을&nbsp;제한하거나&nbsp;중지한&nbsp;때에는&nbsp;그&nbsp;사유&nbsp;및&nbsp;제한기간&nbsp;등을&nbsp;회원에게&nbsp;알려야&nbsp;합니다.
                    <br />
                  </span>
                  <span>
                    제&nbsp;12 조&nbsp;(손해배상)
                    <br />
                  </span>
                  <span>
                    ①
                    회사가&nbsp;위치정보의&nbsp;보호&nbsp;및&nbsp;이용&nbsp;등에&nbsp;관한&nbsp;법률&nbsp;제15조&nbsp;내지&nbsp;제26조의&nbsp;규정을&nbsp;위반한&nbsp;행위로&nbsp;회원에게&nbsp;손해가&nbsp;발생한&nbsp;경우&nbsp;회원은&nbsp;회사에&nbsp;대하여&nbsp;손해배상&nbsp;청구를&nbsp;할&nbsp;수&nbsp;있습니다.
                    이&nbsp;경우&nbsp;회사는&nbsp;고의,
                    과실이&nbsp;없음을&nbsp;입증하지&nbsp;못하는&nbsp;경우&nbsp;책임을&nbsp;면할&nbsp;수&nbsp;없습니다.
                    <br />
                  </span>
                  <span>
                    ②
                    회원이&nbsp;본&nbsp;약관의&nbsp;규정을&nbsp;위반하여&nbsp;회사에&nbsp;손해가&nbsp;발생한&nbsp;경우&nbsp;회사는&nbsp;회원에&nbsp;대하여&nbsp;손해배상을&nbsp;청구할&nbsp;수&nbsp;있습니다.
                    이&nbsp;경우&nbsp;회원은&nbsp;고의,
                    과실이&nbsp;없음을&nbsp;입증하지&nbsp;못하는&nbsp;경우&nbsp;책임을&nbsp;면할&nbsp;수&nbsp;없습니다.
                    <br />
                  </span>
                  <span>
                    제&nbsp;13 조&nbsp;(면책)
                    <br />
                  </span>
                  <span>
                    ①
                    회사는&nbsp;다음&nbsp;각&nbsp;호의&nbsp;경우로&nbsp;서비스를&nbsp;제공할&nbsp;수&nbsp;없는&nbsp;경우&nbsp;이로&nbsp;인하여&nbsp;회원에게&nbsp;발생한&nbsp;손해에&nbsp;대해서는&nbsp;책임을&nbsp;부담하지&nbsp;않습니다.
                    <br />
                  </span>
                  <span>
                    1.
                    천재지변&nbsp;또는&nbsp;이에&nbsp;준하는&nbsp;불가항력의&nbsp;상태가&nbsp;있는&nbsp;경우
                    <br />
                  </span>
                  <span>
                    2.
                    서비스&nbsp;제공을&nbsp;위하여&nbsp;회사와&nbsp;서비스&nbsp;제휴계약을&nbsp;체결한&nbsp;제3자의&nbsp;고의적인&nbsp;서비스&nbsp;방해가&nbsp;있는&nbsp;경우
                    <br />
                  </span>
                  <span>
                    3.
                    회원의&nbsp;귀책사유로&nbsp;서비스&nbsp;이용에&nbsp;장애가&nbsp;있는&nbsp;경우
                    <br />
                  </span>
                  <span>
                    4.
                    제1호&nbsp;내지&nbsp;제3호를&nbsp;제외한&nbsp;기타&nbsp;회사의&nbsp;고의•과실이&nbsp;없는&nbsp;사유로&nbsp;인한&nbsp;경우
                    <br />
                  </span>
                  <span>
                    ②
                    회사는&nbsp;서비스&nbsp;및&nbsp;서비스에&nbsp;게재된&nbsp;정보,
                    자료, 사실의&nbsp;신뢰도,
                    정확성&nbsp;등에&nbsp;대해서는&nbsp;보증을&nbsp;하지&nbsp;않으며&nbsp;이로&nbsp;인해&nbsp;발생한&nbsp;회원의&nbsp;손해에&nbsp;대하여는&nbsp;책임을&nbsp;부담하지&nbsp;아니합니다.
                    <br />
                  </span>
                  <span>
                    제&nbsp;14 조&nbsp;(규정의&nbsp;준용)
                    <br />
                  </span>
                  <span>
                    ①
                    본&nbsp;약관은&nbsp;대한민국법령에&nbsp;의하여&nbsp;규정되고&nbsp;이행됩니다.
                    <br />
                  </span>
                  <span>
                    ②
                    본&nbsp;약관에&nbsp;규정되지&nbsp;않은&nbsp;사항에&nbsp;대해서는&nbsp;관련법령&nbsp;및&nbsp;상관습에&nbsp;의합니다.
                    <br />
                  </span>
                  <span>
                    제&nbsp;15 조&nbsp;(분쟁의&nbsp;조정&nbsp;및&nbsp;기타)
                    <br />
                  </span>
                  <span>
                    ①
                    회사는&nbsp;위치정보와&nbsp;관련된&nbsp;분쟁에&nbsp;대해&nbsp;당사자간&nbsp;협의가&nbsp;이루어지지&nbsp;아니하거나&nbsp;협의를&nbsp;할&nbsp;수&nbsp;없는&nbsp;경우에는&nbsp;위치정보의&nbsp;보호&nbsp;및&nbsp;이용&nbsp;등에&nbsp;관한&nbsp;법률&nbsp;제28조의&nbsp;규정에&nbsp;의한&nbsp;방송통신위원회에&nbsp;재정을&nbsp;신청할&nbsp;수&nbsp;있습니다.
                    <br />
                  </span>
                  <span>
                    ②
                    회사&nbsp;또는&nbsp;고객은&nbsp;위치정보와&nbsp;관련된&nbsp;분쟁에&nbsp;대해&nbsp;당사자간&nbsp;협의가&nbsp;이루어지지&nbsp;아니하거나&nbsp;협의를&nbsp;할&nbsp;수&nbsp;없는&nbsp;경우에는&nbsp;개인정보보호법&nbsp;제43조의&nbsp;규정에&nbsp;의한&nbsp;개인정보분쟁조정위원회에&nbsp;조정을&nbsp;신청할&nbsp;수&nbsp;있습니다.
                    <br />
                  </span>
                  <span>
                    제&nbsp;16 조&nbsp;(회사의&nbsp;연락처)
                    <br />
                  </span>
                  <span>
                    회사의&nbsp;상호&nbsp;및&nbsp;주소&nbsp;등은&nbsp;다음과&nbsp;같습니다.
                    <br />
                  </span>
                  <span>
                    1. 상&nbsp;호&nbsp;: 에스프레스토 주식회사
                    <br />
                  </span>
                  <span>
                    2. 대&nbsp;표&nbsp;자&nbsp;: 손동현
                    <br />
                  </span>
                  <span>
                    3. 주&nbsp;소&nbsp;: 서울시&nbsp;관악구 남부순환로 1895,
                    3층&nbsp;이32(봉천동)
                    <br />
                  </span>
                  <span>
                    4. 대표 전자우편&nbsp;: spresto@spresto.net
                    <br />
                  </span>
                  <span>
                    부칙
                    <br />
                  </span>
                  <span>
                    제1조&nbsp;(시행일)
                    <br />
                  </span>
                  <span>
                    이&nbsp;약관은&nbsp;2020년&nbsp;2월&nbsp;7일부터&nbsp;시행합니다.
                    <br />
                  </span>
                  <span>
                    제2조&nbsp;(위치정보관리책임자)
                    <br />
                  </span>
                  <span>
                    2020년&nbsp;2월&nbsp;7일을&nbsp;기준으로&nbsp;다음과&nbsp;같이&nbsp;지정합니다.
                    <br />
                  </span>
                  <span>
                    1. 소&nbsp;속&nbsp;: 에스프레스토 주식회사&nbsp;개발팀
                    <br />
                  </span>
                  <span>
                    2. 성&nbsp;명&nbsp;: 박성환
                    <br />
                  </span>
                  <span>
                    3. 전자우편: shpark@spresto.net
                    <br />
                  </span>
                  <span>
                    <br />
                  </span>
                </p>
              </article>
            </div>
          </div>
        </div>
      </div>
    </ContactWrapper>
  )
}

const ContactWrapper = styled.section`
  position: relative;
  min-width: 1440px;
  padding: 0 60px 0 60px;
  background-color: ${(props) => props.theme.color.WPrimary};
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 100px;

  @media all and (max-width: 768px) {
    word-break: keep-all;
    min-width: 100%;
    width: 100%;
    margin-top: 3.5rem;
    padding: 0 1.5rem;
  }
`

const Title = styled.div`
  font-size: 36px;
  font-weight: 700;
  margin-top: 50px;

  @media all and (max-width: 768px) {
    min-width: 100%;
    margin-top: 3.5rem;
    line-height: 3.2rem;
    padding: 0 1.5rem;
  }
`

const SubTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-top: 60px;

  @media all and (max-width: 768px) {
    min-width: 100%;
    margin-top: 3.5rem;
    padding: 0 1.5rem;
  }
`

const Description = styled.div`
  font-size: 18px;
  font-weight: 400;
  margin-top: 20px;

  @media all and (max-width: 768px) {
    min-width: 100%;
    margin-top: 0.5rem;
    /* margin-bottom: 1rem; */
    line-height: 2rem;
    padding: 0 1.5rem;
  }
`

const SubDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  @media all and (max-width: 768px) {
    min-width: 100%;
    line-height: 1.5rem;
    padding: 0 1.5rem;
  }
`

const ImgWrapper = styled.div`
  @media all and (max-width: 768px) {
    img {
      margin-top: 3rem;
      width: 21rem;
    }
  }
`
const BtnBox = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  button {
    width: 216px;
    height: 53px;
    border: 1.5px solid ${(props) => props.theme.color.GPrimary};
    border-radius: 10px;
    cursor: pointer;
  }
  @media all and (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    position: static;
    margin-bottom: 2.5rem;
    button {
      width: 10.5rem;
      background-size: 7.5rem;
      height: 3rem;
      margin-right: 0.3rem;
    }
  }
`

const AppStoreBtn = styled.button`
  /* margin-right: 3rem; */
  background: url('/assets/icons/appstore.png') no-repeat center;
`
const GooglePlayBtn = styled.button`
  background: url('/assets/icons/googleplay.png') no-repeat center;
`

export default Terms
