import styled from 'styled-components'

function Terms() {
  return (
    <ContactWrapper>
      <div id="wrapper">
        <div class="contents" id="fullpage" data-ui-parellax="wrapper">
          <div class="contents--outer-wrap section">
            <div class="contents-wrapper">
              <article style={{ lineHeight: '2rem', paddingTop: '2rem' }}>
                <p>릴리의지도 유료 서비스 이용 약관</p>
                <p>
                  <span>&nbsp;</span>
                </p>
                <p>
                  <b>제1장 총 칙</b>
                </p>
                <p>
                  <b>제1조 (목적 )&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b>
                </p>
                <p>
                  이 약관은 주식회사 에스프레스토<span>(</span>이하
                  <span> ‘</span>회사<span>’</span>라 합니다<span>)</span>가
                  제공하는 릴리의지도 유료 서비스를 이용함에 있어 회사와 회원간
                  제반 법률관계 및 기타 관련 사항을 규정함을 목적으로 합니다
                  <span>.</span>
                </p>
                <p>
                  <b>
                    제<span>2</span>조<span> (</span>용어의 정의
                    <span>)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </b>
                </p>
                <p>
                  <span>① </span>이 약관에서 사용되는 용어의 정의는 다음과
                  같습니다<span>.</span>
                </p>
                <p>
                  <span>1. ‘</span>회원가입<span>’</span>이라 함은 릴리의지도
                  서비스를 이용하고자 하는 자<span>(‘</span>고객<span>’</span>
                  이라고도 합니다<span>)</span>가 에스프레스토<span>(</span>주
                  <span>) </span>서비스 이용 약관에 동의하고 회사와 그
                  이용계약을 체결하는 것을 의미합니다<span>. </span>회원가입을
                  하는 경우 고객은 릴리의지도 서비스 회원이 되고<span>, </span>
                  유료 서비스 외 기본적으로 제공하는 서비스를 사용할 수 있습니다
                  <span>. </span>
                </p>
                <p>
                  <span>2. ‘</span>회원 탈퇴<span>’</span>라 함은 회원이
                  릴리의지도 이용약관 또는 릴리의지도 유료 서비스 약관의 체결로
                  발생한 제반 권리와 의무 등의 법률관계를 자의로 영구히
                  종료하거나<span>, </span>포기하는 것을 의미합니다
                  <span>.</span>
                </p>
                <p>
                  <span>3. ‘</span>유료 서비스<span>’</span>라 함은 회원이
                  회사에 일정 금액을 지불해야만 이용할 수 있는 회사의 서비스
                  또는 이용권을 의미하거나<span>, </span>회사 또는 제
                  <span>3</span>자와의 거래 내지 약정 조건을 수락하는 대가로
                  이용하게 되는 회사의 서비스 또는 이용권을 의미합니다
                  <span>. </span>유료 서비스의 세부내용은 각각의 서비스 또는
                  이용권<span>(</span>상품<span>) </span>구매 페이지 및 해당
                  서비스 또는 이용권<span>(</span>상품<span>)</span>의 결제
                  페이지에 상세히 설명되어 있습니다<span>.</span>
                </p>
                <p>
                  <span>4. ‘</span>유료회원<span>’</span>이라 함은 별도의 금액을
                  지불하여 유료 서비스를 구매한 회원 및 회사 또는 제
                  <span>3</span>자가 정하는 이벤트<span>, </span>마케팅에
                  참여하는 등의 방법으로 회사의 유료 서비스를 이용하는 회원을
                  말합니다<span>.</span>
                </p>
                <p>
                  <span>5. ‘</span>무료회원<span>’</span>이라 함은 유료회원이
                  아닌 회원으로 회원가입 이후에 기본적으로 모든 회원에게
                  부여되는 자격을 가진 회원을 의미합니다<span>.</span>
                </p>
                <p>
                  <span>6. ‘</span>결제<span>’</span>라 함은 회원이 특정 유료
                  서비스를 이용하기 위하여 각종 지불수단을 통하여 회사가 정한
                  일정 금액을 회사에 지불하는 것을 의미합니다<span>.</span>
                </p>
                <p>
                  <span>7. ‘</span>구매<span>’</span>라 함은 회사가 유료
                  서비스에 대한 이용 승낙 및 유료 서비스 제공이 가능할 수 있도록
                  회원이 이용하고자 하는 유료 서비스를 선택하여 해당 유료
                  서비스의 가액과 동일한 릴리의지도캐시의 차감을 청구하거나
                  지불수단을 통한 결제로써 그 대가를 지급하는 행위를 의미합니다
                  <span>.</span>
                </p>
                <p>
                  <span>② </span>이 약관에서 사용하는 용어의 정의는 제
                  <span>1</span>항에서 정하는 것을 제외하고는 관계 법령 및
                  서비스별 안내에서 정하는 바에 의합니다<span>.</span>
                </p>
                <p>
                  <b>
                    제<span>3</span>조<span> (</span>약관의 효력 및 변경
                    <span>)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </b>
                </p>
                <p>
                  <span>① </span>이 약관은 유료 서비스 또는 릴리의지도를
                  이용하기를 희망하는 회원이 동의함으로써 효력이 발생하며
                  <span>, </span>회원이 이 약관에 대하여<span> “</span>동의
                  <span>” </span>버튼을 클릭한 경우<span>, </span>이 약관의
                  내용을 충분히 이해하고 그 적용에 동의한 것으로 봅니다
                  <span>.</span>
                </p>
                <p>
                  ② 회사는 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할
                  수 있습니다<span>.</span>회사가 약관을 개정하는 경우에는
                  적용일자 및 변경사유를 명시하여 그 적용일자<span> 15</span>일
                  전부터 홈페이지에 공지합니다<span>. </span>다만<span>, </span>
                  회원에게 불리한 약관의 변경의 경우에는 그 개정 내용을 고객이
                  알기 쉽게 표시하여 그 적용일자<span> 30</span>일 전부터
                  공지하며<span>, </span>이메일 주소<span>, </span>문자메시지
                  등으로 회원에게 개별 통지합니다<span>. </span>회원의 연락처
                  미기재<span>, </span>변경 등으로 인하여 개별 통지가 어려운
                  경우<span>, </span>회원이 등록한 연락처로 공지를 하였음에도
                  <span> 2</span>회 이상 반송된 경우 이 약관에 의한 공지를
                  함으로써 개별 통지한 것으로 간주합니다<span>.</span>
                </p>
                <p>
                  ③ 회사가 제<span>3</span>항의 공지 또는 통보를 하면서 개정
                  약관의 적용<span>/</span>시행일까지 회원이 거부 의사를
                  표시하지 아니할 경우 약관의 개정에 동의한 것으로 간주한다는
                  내용을 고지하였으나<span>, </span>회원이 명시적으로 약관
                  변경에 대한 거부의사를 표시하지 아니하면<span>, </span>회사는
                  회원이 적용<span>/</span>시행일자 부로 변경 약관에 동의한
                  것으로 간주합니다<span>. </span>개정<span>/</span>변경 약관에
                  대하여 거부의사를 표시한 회원은 계약의 해지 또는 회원 탈퇴를
                  선택할 수 있습니다<span>.</span>
                </p>
                <p>
                  ④ 회사는 이 약관을 회원이 그 전부를 인쇄할 수 있고 확인할 수
                  있도록 필요한 기술적 조치를 취합니다<span>.</span>
                </p>
                <p>
                  ⑤ 이 약관은 회원이 이 약관에 동의한 날로부터 회원 탈퇴 시까지
                  적용하는 것을 원칙으로 합니다<span>. </span>단<span>, </span>
                  이 약관의 일부 조항은 회원이 탈퇴 후에도 유효하게 적용될 수
                  있습니다<span>.</span>
                </p>
                <p>
                  ⑥ 이 유료 서비스약관의 제반 규정은<span>, </span>
                  에스프레스토㈜ 서비스 이용 약관에 우선 적용되며<span>, </span>
                  이용약관과 이 약관의 정의<span>, </span>내용 등이 서로
                  상충되는 경우 이 약관의 관련 규정을 적용합니다<span>. </span>
                  이 약관에 명시되지 아니한 사항에 대하여는 콘텐츠산업진흥법
                  <span>, </span>전자상거래 등에서의 소비자 보호에 관한 법률
                  <span>, </span>약관의 규제에 관한 법률 등 관련 법령에 따릅니다
                  <span>."</span>
                </p>
                <p>
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </p>
                <p>
                  <b>
                    <span>2. </span>유료 서비스 이용 계약
                    <span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </b>
                </p>
                <p>
                  <b>
                    제<span>4</span>조<span> (</span>유료 서비스 이용계약의 성립
                    및 승낙의 제한
                    <span>
                      )&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </b>
                </p>
                <p>
                  <span>① </span>유료 서비스 이용계약은 이 약관에 동의한다는
                  의사표시<span>(</span>동의함을 선택<span>)</span>와 함께 이용
                  신청을 하고 회사가 이를 승낙함으로써 비로소 성립합니다
                  <span>.</span>
                </p>
                <p>
                  ② 회원은 유료 서비스 이용계약을 체결하기 전에 해당 유료
                  서비스에 대하여 이 약관에서 회사가 명시<span>, </span>고지하는
                  사항을 숙지하고<span>, </span>착오 없이 정확히 거래할 수
                  있도록 하여야 합니다<span>.</span>
                </p>
                <p>
                  ③ 회사와 회원간 유료 서비스 이용계약은 회사의 승낙이 회원에게
                  도달한 시점<span>(</span>유료 서비스의<span> “</span>구매
                  <span>/</span>결제완료 등<span>”</span>의 표시가 회원에게
                  절차상 표시된 시점<span>)</span>에 성립합니다<span>.</span>
                </p>
                <p>
                  ④ 회사는 릴리의지도 유료 서비스 이용약관 제<span>4</span>조
                  <span>(</span>이용신청에 대한 승낙의 제한<span>)</span>에서
                  정하는 사유가 발생하는 경우<span>, </span>이용신청을 승낙하지
                  아니하거나 소정의 조건 성취 또는 제한 사유가 해소될 때까지
                  일정 기간 동안 승낙을 유보할 수 있습니다<span>.</span>
                </p>
                <p>
                  ⑤ 회원은 유료 서비스 이용 신청을 위한 제반 사항을 기재할 경우
                  회원의 현재의 사실과 일치하는 정보를 기재하여야 하며
                  <span>, </span>회원이 이용하고자 하는 유료 서비스의 결제방법의
                  선택 및 선택한 결제방법에 필요한 결제정보를 정확히 회사에
                  제공하여야 하며<span>, </span>해당 사항이 변경될 경우 지체
                  없이 회사가 정한 절차에 따라 변경사항을 고지<span>, </span>
                  반영하여야 합니다<span>.</span>
                </p>
                <p>
                  ⑥ 회사는 릴리의지도 유료 서비스 이용 약관 제<span>6</span>조
                  <span>(</span>이용신청<span>)</span>의 기재사항 이외에 회원의
                  서비스 이용에 필요한 최소한의 정보를 수집할 수 있습니다
                  <span>. </span>이를 위해 회사가 문의한 사항에 대해 회원은
                  성실하게 고지합니다<span>. </span>회사는 이 경우 수집하는
                  회원의 정보를 릴리의지도 이용약관<span>, </span>
                  개인정보취급방침 및 정보통신망의 이용촉진 및 정보보호 등에
                  관한 법률이 정한 바에 따라 이용<span>, </span>관리합니다
                  <span>.</span>
                </p>
                <p>
                  <b>
                    제<span>5</span>조<span> (</span>결제수단 등
                    <span>)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </b>
                </p>
                <p>
                  <span>① </span>회원이 유료 서비스의 결제를 위하여 이용할 수
                  있는 지불수단은 다음 각 호와 같습니다<span>.</span>
                </p>
                <p>
                  <span>1. </span>
                  <span>인앱 결제 </span>
                </p>
                <p>
                  <span>2. </span>
                  <span>제휴된 통신사 청구서 통합 결제</span>
                </p>
                <p>
                  <span>3. </span>
                  <span>
                    기타 회사가 제<span>3</span>자와 제휴계약 등을 체결하여
                    회원에게 결제 가능 여부 및 그 방법을 안내하게 되는 결제
                    수단에 의한 대금 지급<span>"</span>
                  </span>
                </p>
                <p>
                  <span>② </span>결제를 통해 회원은 아래와 같은 행위를 할 수
                  있습니다<span>.</span>
                </p>
                <p>
                  <span>1. </span>유료 서비스의 가입 및 구입
                </p>
                <p>
                  <span>③ </span>월 정기결제 시 고객이 릴리의지도 서비스를
                  다운로드한 <span>App Store</span>의 약관과 정책에 따라 과금이
                  지속됩니다<span>.</span>
                </p>
                <p>
                  ④ 회사는 릴리의지도 유료 서비스를 이용하는 회원의 거래금액에
                  대하여 내부정책 및 외부 결제업체<span>(</span>은행사
                  <span>, </span>카드사 등<span>), </span>기타 관련 법령의
                  변경에 따라 회원 당 월 누적 결제액<span>, </span>결제한도 등의
                  거래한도를 정할 수 있으며<span>, </span>회원은 회사가 정한
                  거래한도를 초과하는 범위의 유료 서비스를 이용하고자 할 경우
                  거래한도의 초과로 인하여 유료 서비스의 추가 이용이 불가능할 수
                  있습니다<span>.</span>
                </p>
                <p>
                  ⑤ 제<span>4</span>항의 사정이 발생하게 될 경우<span>, </span>
                  회사는 회원이 결제 시 해당 결제창에서 결제 가능 여부를 확인할
                  수 있도록 회원에게 안내합니다<span>."</span>
                </p>
                <p>
                  <b>
                    <span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </b>
                </p>
                <p>
                  <b>
                    <span>3. </span>유료 서비스 이용
                    <span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </b>
                </p>
                <p>
                  <b>
                    <span>
                      제<span>6</span>조<span> (</span>유료 서비스와 유료 회원
                      <span>)&nbsp; </span>
                    </span>
                  </b>
                </p>
                <p>
                  <span>① </span>회사가 회원의 이용신청을 승낙한 때
                  <span>(</span>신규 및 일부 서비스의 경우<span>, </span>이용
                  신청 접수 후 사전 공지한 지정된 일자<span>)</span>로부터 유료
                  서비스는 개시되며<span>, </span>회사의 기술적 사유 등 기타
                  사정에 의하여 서비스를 개시할 수 없는 경우에는 제
                  <span>3</span>조<span>(</span>약관의 효력<span>/</span>변경 등
                  <span>)</span>의 방법에 따라 회원에게 사전 공지합니다
                  <span>.</span>
                </p>
                <p>
                  ② 회사는 회원의 이용신청이 있게 되는 경우<span>, </span>그
                  사실을 통지하며<span>, </span>회사의 통지를 받은 회원은
                  의사표시의 불일치 등이 있는 경우 불일치 사실을 정정 또는
                  수정을 요청하여야 하며<span>, </span>회사는 회원의 요청에 따라
                  처리하여야 합니다<span>. </span>다만<span>, </span>이미 대금을
                  지불한 경우에는 청약 철회 등에 관한
                  <span>
                    제<span>7</span>조
                  </span>
                  <span>(</span>청약 철회 및 서비스 이용계약의 해제
                  <span> • </span>해지<span>)</span>의 규정에 따릅니다
                  <span>.</span>
                </p>
                <p>
                  <span>1. </span>이용권<span>/</span>서비스의 내용
                  <span>/</span>속성에 따른 분류
                </p>
                <p>
                  <span>1) </span>프로모드 카메라 탐지 서비스 <span>:</span>
                  회원이
                  <span>AI </span>기반 탐지 모드로 카메라 탐지 기능 서비스 이용
                  가능
                </p>
                <p>
                  <span>2. </span>이용권<span>/</span>서비스의 이용기간
                  <span>/</span>정기결제 여부에 따른 분류
                </p>
                <p>
                  <span>1) </span>정기결제형 이용권<span> : </span>회원이 등록한
                  결제수단을 통하여 월 단위로 이용요금이 자동으로 결제되고
                  이용기간이 자동 갱신되는 서비스
                </p>
                <p>
                  <span>④ </span>회사는 탐지 기능 외의 기타 콘텐츠나 다른
                  회사와의 계약을 통한 제휴 서비스 등을 제공할 수 있습니다
                  <span>.</span>
                </p>
                <p>
                  ⑤ 회원이 이동전화단말기 제조사<span>, </span>이동통신사와의
                  제휴 유료 서비스에 가입한 경우<span>, </span>이동통신요금제의
                  이용 정지<span>/</span>중단<span>, </span>타요금제로의 변경
                  <span>, </span>제휴사의 멤버십 종류 또는 단말기의 변경 등의
                  사유로 가입 당시 약정한 조건에 부합하지 않게 되는 경우에는
                  해당 제휴서비스가 제공하는 요금 감면<span>, </span>할인 등을
                  받을 수 없으므로 해당 조치 이전에 이용권<span>/</span>서비스
                  안내페이지 등 회사의 고지사항을 사전에 확인하여야 합니다
                  <span>.</span>
                </p>
                <p>
                  <span>&nbsp;</span>
                </p>
                <p>
                  <b>
                    <span>4. </span>유료 서비스의 청약 철회<span>,</span>해지
                    <span>, </span>정지 및 중단
                    <span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </b>
                </p>
                <p>
                  <b>
                    <span>
                      제<span>7</span>조<span> (</span>청
                    </span>
                    약 철회 및 서비스 이용계약의 해제<span> • </span>해지
                    <span> • </span>탈퇴<span>)</span>
                  </b>
                </p>
                <p>
                  <span>① </span>유료회원은 해당 유료 서비스 내지 이용권을 전혀
                  사용하지 아니하였을 경우에 한하여 결제일로부터<span> 7</span>
                  일 이내에 회사에 결제 취소<span>(</span>청약 철회
                  <span>)</span>를 요청할 수 있습니다<span>. </span>
                </p>
                <p>
                  ② 유료회원이 제<span>1</span>항의 청약 철회가 가능한 유료
                  서비스 또는 이용권에 대하여 청약 철회 가능한 기간
                  <span>(</span>결제일로부터<span> 7</span>일 이내<span>)</span>
                  을 경과하여 청약 철회를 신청하거나<span>, </span>전자상거래
                  등에서의 소비자 보호에 관한 법률<span>, </span>
                  콘텐츠산업진흥법<span>, </span>콘텐츠이용자보호지침 등 기타
                  관계 법령에서 정한 청약 철회 제한 사유에 해당하는 콘텐츠의
                  경우에 해당하는 경우에는 청약 철회가 제한됩니다<span>.</span>
                </p>
                <p>
                  ③ 청약 철회 및 회원 탈퇴는 회원이 고객센터 이메일을 통해 할 수
                  있으며<span>, </span>회사에 의사를 표시하여 회사에 도달될 때
                  그 효력이 발생하고<span>, </span>회사는 회원의 의사표시를
                  수령한 후 지체 없이 이러한 사실을 회원에게 회신합니다
                  <span>. </span>
                </p>
                <p>
                  ④ 회사는 회원이 청약 철회<span>, </span>해지<span>/</span>해제
                  <span>, </span>회원 탈퇴 신청을 확인 후 환불 금액이 있을 경우
                  <span>, </span>원칙적으로 회원의 해당 의사표시를 수령한
                  날로부터<span> 3</span>영업일 이내에 결제수단 별 사업자에게
                  대금의 청구 정지 내지 취소를 요청하고<span>, </span>회원이
                  결제한 동일 결제수단으로 환불함을 원칙으로 합니다
                  <span>.</span>단<span>, </span>회사가 사전에 회원에게 공지한
                  경우 및 아래의 각 경우와 같이 개별 결제 수단별 환불 방법
                  <span>, </span>환불 가능 기간 등이 차이가 있을 수 있습니다
                  <span>.</span>
                </p>
                <p>
                  <span>1. </span>신용카드 등 수납 확인이 필요한 결제수단의
                  경우에는 수납 확인일로부터<span> 3</span>영업일 이내
                </p>
                <p>
                  <span>2. </span>결제수단 별 사업자가 회사와의 약정을 통하여
                  사전에 대금 청구 정지 내지 결제 취소 가능 기한 등을 미리
                  정하여 둔 경우로 해당 기한을 지난 환불의 경우
                </p>
                <p>
                  <span>3. </span>회원이 유료 서비스<span>/ </span>이용권의 이용
                  결과<span>, </span>얻은 이익이 있거나 중도 해지의 경우
                </p>
                <p>
                  <span>4. </span>회원이 환불 처리에 필요한 정보 내지 자료를
                  회사에 즉시 제공하지 않는 경우
                </p>
                <p>
                  <span>(</span>현금 환불 시 신청인의 계좌 및 신분증 사본을
                  제출하지 아니하거나<span>, </span>타인 명의의 계좌를 제공하는
                  경우 등<span>)</span>
                </p>
                <p>
                  <span>5. </span>해당 회원의 명시적 의사표시가 있는 경우
                  <span>"</span>
                </p>
                <p>
                  ⑥ 회사는 회원이 구매한 대금에서 회원이 실제 이용한 기간 등
                  서비스 또는 이용권을 통하여 취득한 이익을 감안<span>, </span>
                  합리적인 범위 내에서 회사가 적용하는 소정의 기준율을 적용하여
                  차감하고 환불합니다<span>.</span>
                </p>
                <p>
                  ⑦ 회사는 콘텐츠이용자보호지침 등에 따라<span>, </span>회사가
                  부담하였거나 부담할 부대비용<span>, </span>수수료를 차감하여
                  환불할 수 있습니다<span>.</span>
                </p>
                <p>
                  ⑧ 회원이 유료 서비스 또는 이용권을 선물 받거나<span>, </span>
                  프로모션 등을 통해 무료<span>/</span>무상으로 취득하는 등
                  회원이 직접 비용을 지불하지 아니한 서비스에 대하여는 회사는
                  환불 의무를 부담하지 않습니다<span>.</span>
                </p>
                <p>
                  ⑨ 회원이 이 약관에서 정하는 회원의 의무를 위반하였을 경우
                  <span>, </span>계약을 해제<span>, </span>해지하거나 서비스
                  이용 제한<span>, </span>손해배상 청구 등의 조치를 취할 수
                  있으며<span>, </span>계약 해지 후 환불하여야 할 금액이 있을
                  경우에는 일정한 범위 내에서 회원이 부담할 금액을 공제하고
                  환불합니다<span>. </span>이 경우 회원은 해당 회사의 조치에
                  대하여 회사가 정한 절차에 따라 이의 신청을 할 수 있으며
                  <span>, </span>회사는 정당하다고 확인하는 경우 서비스 이용
                  재개 등을 할 수 있으며<span>, </span>이용자가 자신의 고의
                  <span>, </span>과실 없었음을 입증한 경우 회사는 서비스를
                  정지한 기간만큼 이용 기간을 연장합니다<span>.</span>
                </p>
                <p>
                  <span>⑩ </span>회원의 신청 또는 동의에 따라 월 정기결제 중인
                  유료 서비스의 경우<span>, </span>해당 회원이 유료 서비스의
                  이용요금을 체납하는 경우 연체가 발생한 날 자동으로 이용권
                  해지가 될 수 있으므로<span>, </span>월 정기결제를 통한 혜택을
                  유지하고자 하는 회원은 이용요금의 체납 또는 결제수단의 연체가
                  발생하지 않도록 사전에 조치하여야 합니다<span>.</span>
                </p>
                <p>
                  ⑪ 월 정기결제 유료 서비스를 이용 중인 회원이 탈퇴하는 경우
                  해당 이용권은 즉시 해지되며<span>, </span>이 경우 회원의
                  정보와 게시물 작성 등 릴리의지도 유료 서비스 이용내용은 관련
                  법령이 정하는 경우를 제외하고는 릴리의지도 유료 서비스 이용
                  약관에 따릅니다<span>.</span>
                </p>
                <p>
                  ⑫ 회원 탈퇴 시 및 계약 해지<span>/</span>해제 시 릴리의지도
                  쿠폰 또는 포인트 잔액이 남아 있는 경우<span>, </span>이를
                  환불받거나 신청 전에 소진하여야 합니다<span>.</span>회원이
                  자진 탈퇴한 경우<span>, </span>회사는 해당 릴리의지도의 쿠폰을
                  포함한 혜택의 복구 등에 대한 일체의 책임을 부담하지 아니합니다
                  <span>.</span>
                </p>
                <p>
                  <b>
                    <span>&nbsp;</span>
                  </b>
                </p>
                <p>
                  <b>
                    <span>
                      제<span>8</span>조<span> (</span>과오금
                      <span>)&nbsp;&nbsp;</span>
                    </span>
                  </b>
                </p>
                <p>
                  <span>① </span>회사는 유료 서비스 결제와 관련하여 과오금이
                  발생한 경우 이용대금의 결제와 동일한 방법으로 과오금 전액을
                  환불합니다<span>. </span>다만<span>, </span>동일한 방법으로
                  환불이 불가능할 때는 이를 사전에 고지합니다<span>.</span>
                </p>
                <p>
                  ② 회사의 귀책사유로 과오금이 발생한 경우 과오금 전액을
                  환불합니다<span>. </span>단<span>, </span>회원의 귀책사유로
                  과오금이 발생한 경우<span>, </span>회사가 과오금을 환불하는데
                  소요되는 비용은 합리적인 범위 내에서 이용자가 부담하여야 하며
                  <span>, </span>회사는 해당 비용을 차감 후 과오금을 환불할 수
                  있습니다<span>.</span>
                </p>
                <p>
                  ③ 회사는 회원이 요구하는 과오금에 대하여 환불을 거부할 경우
                  <span>, </span>정당하게 유료 서비스 요금이 부과되었음을 입증할
                  책임을 부담합니다<span>.</span>
                </p>
                <p>
                  ④ 회사는 과오금의 세부 환불절차 및 기타 사항에 대하여 다음과
                  같이 콘텐츠 이용자보호지침이 정하는 바에 따릅니다
                  <span>.</span>
                </p>
                <p>
                  <span>1. </span>회사나 회원이 과오금의 발생사실을 안 때에는
                  이메일 등 회사가 제시한 방법에 따라 상대방에게 통보
                </p>
                <p>
                  <span>2. </span>회사는 회원에게 환불에 필요한 정보를 요청
                  <span>(</span>회원 성명<span>, </span>결제증빙서류
                  <span>, </span>전화번호<span>, </span>환불 요청 계좌 등
                  <span>)</span>
                </p>
                <p>
                  <span>3. </span>회원은 환불에 필요한<span> 2.</span>호의
                  정보를 회사에 제공
                </p>
                <p>
                  <span>4. </span>회사는 이용자의 정보 제공일로부터
                  <span> 7</span>일 이내 환불 처리
                </p>
                <p>
                  <span>(</span>회원의 명시적 의사표시가 있는 경우 차회 요금에서
                  상계 또는 릴리의지도 쿠폰 또는 포인트로제공<span>)</span>
                </p>
                <p>
                  <b>
                    제<span>9</span>조<span> (</span>유료 서비스의 정지
                    <span>, </span>중단<span>) </span>
                  </b>
                </p>
                <p>
                  <span>① </span>회사는 원칙적으로 연중무휴<span> 1</span>일
                  <span>24</span>시간 유료 서비스를 제공합니다<span>.</span>
                </p>
                <p>
                  ② 회사는 이용자에 대한 서비스 개선을 목적으로 하는 설비 점검
                  및 보수 시에는 릴리의지도 유료 서비스의 전부 또는 일부의
                  제공을 제한<span>, </span>중지<span>, </span>중단할 수
                  있습니다<span>. </span>이 경우 회사는 가능한 한 그 중단사유
                  <span>, </span>중단 기간 등을 이 약관 제<span>3</span>조
                  <span>(</span>약관의 효력<span>/</span>변경 등<span>)</span>의
                  방법을 통하여 사전에 회원에게 공지하며<span>, </span>아래 각
                  호의 불가피한 경우에는 경위와 원인이 확인된 즉시 사후에 공지할
                  수 있습니다<span>.</span>
                </p>
                <p>
                  <span>1. </span>회원<span>, </span>기타 불특정 제
                  <span>3</span>자의 불법<span>, </span>범죄행위로 인하여
                  기술적으로 정상적인 서비스의 제공이 어려운 경우
                </p>
                <p>
                  <span>2. </span>시스템 또는 기타 서비스 설비의 장애
                  <span>, </span>유무선<span> Network </span>장애 또는 유료
                  서비스 이용의 폭주 등으로 정상적인 유료 서비스 제공이 불가능할
                  경우
                </p>
                <p>
                  <span>3. </span>기타 천재지변<span>, </span>국가비상사태
                  <span>, </span>정전 등 회사가 통제할 수 없는 불가항력적 사유로
                  인한 경우
                </p>
                <p>
                  <span>③ </span>회사는 제<span>2</span>항의 각 호에 해당하는
                  사유가 아닌 회사의 귀책사유로 서비스의 제공이 중단됨으로
                  인하여 회원이 입은 손해에 대하여 아래와 같이 콘텐츠
                  이용자보호지침에서 정하는 바에 따라 배상합니다<span>. </span>
                  다만<span>, </span>천재지변 등 불가항력으로 인한 경우는 아래
                  이용중지 또는 장애발생 시간에 산입하지 아니합니다
                  <span>. </span>또한<span>, </span>각 호를 적용함에 있어
                  사전고지는 서비스 중지<span>, </span>장애시점을 기준으로
                  <span> 24</span>시간 이전에 고지된 것에 한합니다<span>.</span>
                </p>
                <p>
                  <span>1. </span>사업자가 서비스의 중지<span>•</span>장애에
                  대하여 사전고지 하지 않은 경우에 있어서 이용자의 피해구제 등은
                  다음 각호에 의합니다<span>. </span>다만<span>, </span>이용자의
                  책임 있는 사유로 인하여 서비스가 중지되거나 장애가 발생한 경우
                  서비스 중지<span>•</span>장애시간에 포함하지 아니합니다
                  <span>.</span>
                </p>
                <p>
                  <span>1) 1</span>개월 동안의 서비스 중지<span>•</span>장애발생
                  누적시간이<span> 72</span>시간을 초과한 경우<span> : </span>
                  계약해제 또는 해지 및 미이용기간을 포함한 잔여기간에 대한
                  이용료 환급 및 손해배상<span>(</span>단<span>,</span>사업자가
                  고의 또는 과실 없음을 입증한 경우 손해배상책임을 지지 않음
                  <span>)</span>
                </p>
                <p>
                  <span>2) </span>사업자의 책임 있는 사유로 인한 서비스 중지
                  또는 장애의 경우<span> : </span>서비스 중지<span>•</span>
                  장애시간의<span> 3</span>배를 무료로 연장
                </p>
                <p>
                  <span>3) </span>불가항력 또는 제<span>3</span>자의 불법행위
                  등으로 인해 서비스의 중지 또는 장애가 발생한 경우
                  <span> : </span>계약을 해제할 수는 없지만<span>, </span>
                  서비스의 중지 또는 장애시간만큼 무료로 이용기간을 연장
                </p>
                <p>
                  사업자가 서비스의 중지<span>•</span>장애에 대하여 사전고지한
                  경우에 있어서 이용자의 피해구제 등은 다음 각호에 의합니다
                  <span>. </span>다만<span>, </span>서비스 개선을 목적으로 한
                  설비 점검 및 보수시<span> 1</span>개월을 기준으로 최대
                  <span> 24</span>시간은 중지<span>•</span>장애 시간에 포함하지
                  아니합니다<span>.</span>
                </p>
                <p>
                  가<span>) 1</span>개월을 기준으로 서비스 중지<span>•</span>
                  장애시간이<span> 10</span>시간을 초과하는 경우
                  <span> : 10</span>시간과 이를 초과한 시간의<span> 2</span>배의
                  시간만큼 이용기간을 무료로 연장
                </p>
                <p>
                  나<span>) 1</span>개월을 기준으로 서비스 중지<span>•</span>
                  장애시간이<span> 10</span>시간을 초과하지 않은 경우
                  <span> : </span>중지<span>•</span>장애 시간에 해당하는 시간을
                  무료로 연장
                </p>
                <p>
                  <span>④ </span>회사는 무료로 제공되는 서비스의 일부 또는
                  전부를 회사의 정책<span>, </span>운영상의 긴요한 사유로 수정
                  <span>, </span>중단<span>, </span>변경할 수 있으며
                  <span>, </span>이에 대하여 관련 법령에 별도 규정이 있지
                  아니하는 한 별도의 보상을 하지 않습니다<span>.</span>
                </p>
                <p>
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </p>
                <p>
                  <b>
                    <span>5. </span>계약당사자의 의무<span>, </span>손해배상 등
                    <span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </b>
                </p>
                <p>
                  <b>
                    제<span>10</span>조<span> (</span>회원의 의무<span>, </span>
                    위반시 회사의 조치 등
                    <span>)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </b>
                </p>
                <p>
                  <span>① </span>회원은 회사가 제공하는 유료 서비스 및
                  릴리의지도 쿠폰<span>, </span>포인트 등 이용 시 관계법령
                  <span>, </span>약관<span>, </span>세부이용지침<span>, </span>
                  서비스 이용안내 및 사이트 내 공지한 주의사항<span>, </span>
                  회사가 서비스 이용과 관련하여 회원에게 통지하는 사항 등을
                  준수하여야 하며<span>,</span>기타 회사 및 타인의 명예를
                  훼손하거나 릴리의지도 서비스 운영 기타 업무수행에 지장을
                  초래하는 행위를 해서는 안됩니다<span>.</span>
                </p>
                <p>
                  ② 회원은 로그인 정보를 관리할 책임이 있으며<span>, </span>
                  본인이 아닌 타인이 유료 서비스 및 릴리의지도 쿠폰
                  <span>, </span>포인트 등 사용하게 하여서는 안됩니다
                  <span>. </span>이를 위반하여 회원의 개인정보를 타인이 사용하여
                  릴리의지도 유료 서비스 및 릴리의지도 쿠폰<span>, </span>포인트
                  등을 이용함으로써 발생한 결과에 대한 책임은 회원에게 있습니다
                  <span>. </span>
                </p>
                <p>
                  ③ 회원은 회사가 사전에 허용한 경우를 제외하고는 유료 서비스 및
                  릴리의지도 쿠폰<span>, </span>포인트 등 을 영리목적으로
                  이용하거나 이 약관에서 금지하는 행위를 하거나 허용하는 범위를
                  벗어난 이용행위를 하여서는 안됩니다<span>.</span>
                </p>
                <p>
                  ④ 회원은 유료 서비스 및 릴리의지도 쿠폰<span>, </span>포인트
                  등을 이용과정에서 위법행위 또는 선량한 풍속 기타 사회질서에
                  반하는 행위를 하여서는 아니 되며<span>, </span>유료 서비스 및
                  릴리의지도 쿠폰<span>, </span>포인트 를 제<span>3</span>자와
                  유상으로 거래하거나 현금으로 전환하는 행위를 하여서는 안됩니다
                  <span>.</span>
                </p>
                <p>
                  ⑤ 회원은 유료 서비스 및 릴리의지도 쿠폰<span>, </span>포인트를
                  이용하여 얻은 정보를 회사의 사전 승낙 없이 서비스의 이용
                  이외의 목적으로 사용하여서는 안됩니다<span>.</span>
                </p>
                <p>
                  ⑥ 회원은 유료 서비스를 구매하여 이용하는 기능 및 작성한
                  게시물에 대하여 사적 이용 범위 내에서 사용하여야 하고 영리를
                  목적으로 하는 공공장소에서 공개 및 타인에게 공유하는 등의 방법
                  등으로 사용할 수 없습니다<span>. </span>또한 해당 게시물을
                  복제하거나 다운받아 불법적으로 유포<span>, </span>공유하여서는
                  아니 되며<span>, </span>이를 위반하여 발생한 제반 문제에 대한
                  책임은 회원 본인에게 있습니다<span>.</span>
                </p>
                <p>
                  <span>(</span>이 경우<span>, </span>국내외 제<span>3</span>
                  자의 저작권 등을 침해하는 행위로서 회사가<span> IP </span>접속
                  차단 등 기술적인 조치를 통하여 타인에 대한 권리 침해 방지
                  조치를 취하였음에도 불구하고 이용자가 고의로 또는 회사를
                  기망하는 수단과 방법을 통하여 릴리의지도 사이트에 접속하는 등
                  제<span>3</span>자의 저작권 등을 침해하는 행위를 포함합니다
                  <span>.)</span>
                </p>
                <p>
                  ⑦ 회사는 제<span>1</span>항 내지 제<span>6</span>항의 회원의
                  위반 행위가 있는 경우 해당 회원에 대하여 위반 사실을 고지하고
                  서비스 제공을<span> 1</span>개월간 정지시킬 수 있고
                  <span>, </span>동위반행위가 재발할 경우 서비스 이용계약을 해제
                  또는 해지할 수 있습니다<span>. </span>본항의 회사의 의사표시는
                  회원에게 도달한 날로부터 효력이 발생합니다<span>. </span>
                  회사의 해제<span>/</span>해지 및 이용 정지에 대하여 회원은
                  회사가 정한 절차에 따라 이의신청할 수 있습니다<span>. </span>
                  이 경우 이용자가 자신의 고의<span>, </span>과실 없었음을
                  입증하거나 회원의 이의가 정당하다고 회사가 인정하는 경우
                  회사는 계정을 정지한 기간만큼 이용기간을 연장합니다
                  <span>.</span>
                </p>
                <p>
                  ⑧ 유료 서비스 이용과 관련하여 진실한 정보를 입력하지 않은
                  회원은 법적인 보호를 받을 수 없으며<span>, </span>서비스
                  이용에 제한을 받을 수 있습니다<span>.</span>
                </p>
                <p>
                  ⑨ 민법에 의한 미성년회원이 유료 서비스 내지 이용권을
                  이용하고자 하는 경우<span>,</span>법정대리인<span>(</span>
                  부모님<span>)</span>의 동의를 얻거나 계약 체결 후 추인을 얻지
                  않으면 미성년자 본인 또는 법정대리인이 그 계약을 취소할 수
                  있습니다<span>. </span>만<span>14</span>세 미만 아동이
                  서비스를 이용하기 위한 정보 제공 시에는 법정대리인의 동의를
                  받아야 합니다<span>.</span>
                </p>
                <p>
                  ⑩ 청소년 유해 매체물을 이용하기 위해서는 만<span> 19</span>세
                  이상의 회원이어야 하며<span>(</span>단<span>, </span>만
                  <span>19</span>세에 도달하는 해의<span> 1</span>월
                  <span> 1</span>일을 맞이한 자를 포함합니다<span>), </span>정보
                  통신망 이용촉진 및 정보 보호 등에 관한 법률 및 청소년 보호법의
                  규정에 의한 인증수단을 통해 성인 인증을 받아야 합니다
                  <span>. </span>인증을 받지 않는 경우<span>, </span>해당
                  서비스의 이용이 제한됩니다<span>."</span>
                </p>
                <p>
                  <b>
                    제<span>11</span>조<span> (</span>손해배상
                    <span>
                      )&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </b>
                </p>
                <p>
                  <span>① </span>회사는 서비스의 결함에 의하여 회사가 제공하는
                  유료 서비스의 내용인 콘텐츠가 손상<span>, </span>훼손
                  <span>, </span>삭제되어 서비스 이용에 손해를 입은 회원에게
                  해당 콘텐츠의 복원 또는 릴리의지도 쿠폰 또는 포인트 등으로
                  배상합니다<span>. </span>회원에게 손해가 발생한 경우 회사는
                  회원에게 실제 발생한 손해만을 배상합니다<span>. </span>다만
                  <span>, </span>회사의 고의 또는 과실 없이 회원에게 발생한
                  일체의 손해에 대하여는 책임을 지지 아니합니다<span>. </span>
                  회사는 이 약관에서 명시되지 아니한 회사의 귀책사유로 인하여
                  유료 서비스의 이용 회원에게 손해가 발생한 경우 회사의 배상
                  책임과 관련하여 회사는<span> '</span>콘텐츠 이용자 보호지침
                  <span>'</span>의 관련 규정 및 기타 상관례를 따릅니다
                  <span>.</span>
                </p>
                <p>
                  ② 고객이 이 약관의 이용 제한 관련 각 규정에 근거
                  <span>, </span>서비스의 이용이 제한되거나 이용계약이 해지된
                  경우<span>, </span>고객이 보유한 모든 이용권은 상실되며 회사는
                  해당 이용권의 구매대금을 반환할 의무를 부담하지 아니합니다
                  <span>.</span>
                </p>
                <p>
                  ③ 회원이 이 약관상의 의무를 위반함으로 인하여 회사에 손해가
                  발생한 경우 또는 회원이 릴리의지도 유료 서비스와 쿠폰
                  <span>, </span>포인트 등을 이용함에 있어 회사에 손해를 입힌
                  경우<span>, </span>회원은 회사에 그 손해를 배상하여야 합니다
                  <span>."</span>
                </p>
                <p>
                  <b>
                    제<span>12</span>조<span> (</span>면책
                    <span>)&nbsp;&nbsp;&nbsp;</span>
                  </b>
                </p>
                <p>
                  <span>① </span>회사는 다음 각 호의 사유로 인하여 회원 또는 제
                  <span>3</span>자에게 발생한 손해에 대하여는 그 책임을 지지
                  아니합니다<span>.</span>
                </p>
                <p>
                  <span>1. </span>천재지변 또는 이에 준하는 불가항력으로 인해
                  릴리의지도 유료 서비스 또는 쿠폰<span>, </span>포인트를 제공할
                  수 없는 경우
                </p>
                <p>
                  <span>2. </span>회원이 자신의 로그인 정보 등의 관리를 소홀히
                  한 경우
                </p>
                <p>
                  <span>3. </span>회사의 관리영역이 아닌 공중통신선로의 장애로
                  서비스이용이 불가능한 경우
                </p>
                <p>
                  <span>4. </span>기타 회사의 귀책사유가 없는 통신서비스 등의
                  장애로 인한 경우
                </p>
                <p>
                  ② 회사는 회원이 릴리의지도 유료 서비스 또는 쿠폰
                  <span>, </span>포인트를 이용하여 기대하는 수익을 얻지 못하거나
                  상실한 것<span>, </span>서비스에 게시된 게시물에 대한 취사
                  선택 또는 이용으로 발생하는 손해 등에 대해서는 책임을 지지
                  않습니다<span>. </span>또한 회원이 사이트에 게재한 리뷰
                  <span>, </span>평점 등 게시물의 정확성 등 내용에 대하여는
                  책임을 지지 않습니다<span>.</span>
                </p>
                <p>
                  ③ 회사는 회원 상호간 또는 회원과 제<span>3</span>자 상호간에
                  릴리의지도 유료 서비스 또는 쿠폰<span>, </span>포인트와
                  관련하여 발생한 분쟁에 대하여 개입할 의무가 없으며
                  <span>, </span>회사에 귀책사유가 없는 한 이로 인하여 발생한
                  손해를 배상할 책임이 없습니다<span>."</span>
                </p>
                <p>
                  <b>
                    제<span>13</span>조<span> (</span>분쟁의 해결
                    <span>)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </b>
                </p>
                <p>
                  <span>① </span>유료 서비스 이용과 관련하여 회사와 회원 사이에
                  분쟁이 발생한 경우<span>, </span>회사와 회원은 분쟁의 해결을
                  위해 성실히 협의하고<span>, </span>협의가 안될 경우
                  콘텐츠산업진흥법 상 콘텐츠분쟁 조정 위원회에 분쟁조정을 신청할
                  수 있습니다<span>.</span>
                </p>
                <p>
                  ② 전항에 따라 분쟁이 해결되지 않을 경우 양 당사자는 소를
                  제기할 수 있으며<span>,</span>회사와 회원 간의 소의 관할은
                  제소 당시의 이용자의 주소에 의하고<span>, </span>주소가 없는
                  경우 거소를 관할하는 지방법원의 전속 관할로 합니다
                  <span>.</span>
                </p>
                <p>
                  ③ 제소 당시 회원의 주소 또는 거소가 분명하지 않은 경우에는
                  민사소송법에 따라 관할법원을 정합니다<span>.</span>
                </p>
                <p>
                  <span>&nbsp;</span>
                </p>
                <p>
                  <span>(</span>시행일<span>) </span>이 약관은 <span>2020</span>
                  <span>
                    년 <span>5</span>
                    <span>
                      월<span> 1</span>일
                    </span>
                    부터 시행합니다<span>.</span>
                  </span>
                </p>
              </article>{' '}
            </div>
          </div>
        </div>
      </div>
    </ContactWrapper>
  )
}

const ContactWrapper = styled.section`
  position: relative;
  min-width: 1440px;
  padding: 0 60px 0 60px;
  background-color: ${(props) => props.theme.color.WPrimary};
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 100px;

  @media all and (max-width: 768px) {
    word-break: keep-all;
    min-width: 100%;
    width: 100%;
    margin-top: 3.5rem;
    padding: 0 1.5rem;
  }
`

const Title = styled.div`
  font-size: 36px;
  font-weight: 700;
  margin-top: 50px;

  @media all and (max-width: 768px) {
    min-width: 100%;
    margin-top: 3.5rem;
    line-height: 3.2rem;
    padding: 0 1.5rem;
  }
`

const SubTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-top: 60px;

  @media all and (max-width: 768px) {
    min-width: 100%;
    margin-top: 3.5rem;
    padding: 0 1.5rem;
  }
`

const Description = styled.div`
  font-size: 18px;
  font-weight: 400;
  margin-top: 20px;

  @media all and (max-width: 768px) {
    min-width: 100%;
    margin-top: 0.5rem;
    /* margin-bottom: 1rem; */
    line-height: 2rem;
    padding: 0 1.5rem;
  }
`

const SubDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  @media all and (max-width: 768px) {
    min-width: 100%;
    line-height: 1.5rem;
    padding: 0 1.5rem;
  }
`

const ImgWrapper = styled.div`
  @media all and (max-width: 768px) {
    img {
      margin-top: 3rem;
      width: 21rem;
    }
  }
`
const BtnBox = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  button {
    width: 216px;
    height: 53px;
    border: 1.5px solid ${(props) => props.theme.color.GPrimary};
    border-radius: 10px;
    cursor: pointer;
  }
  @media all and (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    position: static;
    margin-bottom: 2.5rem;
    button {
      width: 10.5rem;
      background-size: 7.5rem;
      height: 3rem;
      margin-right: 0.3rem;
    }
  }
`

const AppStoreBtn = styled.button`
  /* margin-right: 3rem; */
  background: url('/assets/icons/appstore.png') no-repeat center;
`
const GooglePlayBtn = styled.button`
  background: url('/assets/icons/googleplay.png') no-repeat center;
`

export default Terms
