import { useEffect, useState } from 'react'
import styled from 'styled-components'
import PageTitle from '../../../components/PageTitle'
import { useTranslation } from 'react-i18next'

function News() {
  const { t } = useTranslation()
  const PageTitleSubText = t('news_title')

  const [defaultView, setDefaultView] = useState([])
  const [isAddView, setIsAddView] = useState(false)
  const [addView, setAddView] = useState([])
  const [openAddView, setOpen] = useState(false)

  useEffect(() => {
    fetch('/data/About/News/data.json')
      .then((res) => res.json())
      .then((data) => {
        setDefaultView(data.slice(0, 4))
        setAddView(data.slice(4))
        setIsAddView(data.length > 4)
      })
  }, [])
  return (
    <>
      <PageTitle title="Latest News" subtext={PageTitleSubText} />
      <Container>
        <CardContainer>
          {defaultView.map((data) => (
            <Card backgroundImg={data?.img} key={data.id}>
              <ContentWrapper>
                <Title>{t(data?.title)}</Title>
                <Content>{t(data?.content)}</Content>
                <div>
                  <Date>{data?.date}</Date>
                  <CardButton href={data?.url}>{t('more')}</CardButton>
                </div>
              </ContentWrapper>
            </Card>
          ))}
          {isAddView &&
            openAddView &&
            addView?.map((data) => (
              <Card backgroundImg={data?.img} key={data.id}>
                <ContentWrapper>
                  <Title>{t(data?.title)}</Title>
                  <Content>{t(data?.content)}</Content>
                  <div>
                    <Date>{data?.date}</Date>
                    <CardButton href={data?.url}>{t('more')}</CardButton>
                  </div>
                </ContentWrapper>
              </Card>
            ))}
        </CardContainer>
        {isAddView && !openAddView && (
          <Button
            type="button"
            onClick={() => {
              setOpen(true)
            }}
          >
            {t('news_more')}
          </Button>
        )}
        {openAddView && (
          <Button
            type="button"
            onClick={() => {
              setOpen(false)
              window.scrollTo(0, 0)
            }}
          >
            {t('news_close')}
          </Button>
        )}
      </Container>
    </>
  )
}

export default News

const Container = styled.div`
  ${({ theme }) => theme.mixins.flexBox('column')}
`

const CardContainer = styled.div`
  margin: 88px 60px 58px;
  ${({ theme }) => theme.mixins.flexBox('row', 'flex-start', 'space-between')}
  flex-wrap: wrap;

  @media all and (max-width: 768px) {
    width: 100%;
    margin-top: 3rem;
    padding: 0 1.5rem;
    margin-bottom: 2rem;
  }
`

const Card = styled.div`
  margin-bottom: 62px;
  ${({ theme }) => theme.mixins.flexBox('row', 'flex-end', 'center')}
  background-image: url(${(props) => props.backgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 630px;
  height: 630px;
  box-shadow: 0px 15px 30px rgba(2, 40, 43, 0.1);
  border-radius: 20px;

  &:hover {
    p {
      display: block;
    }
  }

  @media all and (max-width: 768px) {
    height: 21rem;
    margin-bottom: 2rem;
    background-size: contain;
    background-position: unset;
    box-shadow: unset;
  }
`
const ContentWrapper = styled.div`
  padding: 30px 37.89px 32px;
  margin: 24px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.WPrimary};

  div {
    margin-top: 30px;
    ${({ theme }) => theme.mixins.flexBox('row', 'center', 'space-between')};
  }

  @media all and (max-width: 768px) {
    margin: 0;
    padding: 1rem 0rem;
    border-radius: 0px 0px 8px 8px;

    div {
      margin-top: 0.5rem;
    }
  }
`

const Title = styled.h3`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #333333;
  @media all and (max-width: 768px) {
    font-size: 1rem;
    color: #666666;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

const Content = styled.p`
  display: none;
  padding: 17px 0 13px;
  font-weight: 400;
  font-size: 17.5px;
  line-height: 26px;
  color: #9f9f9f;
`

const Date = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #9a9a9a;
  @media all and (max-width: 768px) {
    color: #c4c4c4;
    font-size: 0.9rem;
  }
`

const CardButton = styled.a`
  height: 28px;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.GPrimary};
  background-color: inherit;
  border-bottom: 4px solid ${({ theme }) => theme.color.GPrimary};
  cursor: pointer;
  @media all and (max-width: 768px) {
    font-size: 0.9rem;
    border-bottom: 2px solid ${({ theme }) => theme.color.GPrimary};
    line-height: 1.8rem;
    color: #00bac7;
    font-weight: bold;
  }
`

const Button = styled.button`
  margin-bottom: 208px;
  padding: 20px 50px;
  background: ${({ theme }) => theme.color.GPrimary};
  box-shadow: 0px 15px 30px rgba(0, 186, 199, 0.33);
  border: none;
  border-radius: 100px;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: ${({ theme }) => theme.color.WPrimary};
  cursor: pointer;
  @media all and (max-width: 768px) {
    font-size: 1rem;
    padding: 0.7rem 2.7rem;
    box-shadow: 0px 15px 30px rgb(0 186 199 / 20%);
    margin-bottom: 5rem;
  }
`
