import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/effect-fade'
import { Pagination, Navigation, Autoplay, EffectFade } from 'swiper'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function SliderProduct() {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024)

  const clickBtn = () => {
    navigate('/business/product')
  }
  useEffect(() => {
    fetch('/data/Main/SliderProduct/data.json')
      .then((res) => res.json())
      .then((data) => {
        setData(data)
      })
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024)
    }

    handleResize()

    // 이벤트 리스너 추가
    window.addEventListener('resize', handleResize)

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Container>
      <Header>
        <Label>
          <hr />
          business
        </Label>
        <Title>Product</Title>
        <SubText>{t('main_products_title')}</SubText>
      </Header>

      {isDesktop ? (
        <StyledSwiper
          key="pc"
          modules={[Pagination, Navigation, Autoplay, EffectFade]}
          direction="vertical"
          effect="fade"
          fadeEffect={{ crossFade: true }}
          slidesPerView={1}
          loop={true}
          speed={500}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            type: 'fraction',

            formatFractionCurrent: function (number) {
              return ('0' + number).slice(-2)
            },
            formatFractionTotal: function (number) {
              return ('0' + number).slice(-2)
            },
            renderFraction: function (currentClass, totalClass) {
              return (
                '<span class="' +
                currentClass +
                '"></span>' +
                ' - ' +
                '<span class="' +
                totalClass +
                '"></span>'
              )
            },
          }}
          className="mySwiper SwiperBody"
        >
          {data.map((data) => {
            return (
              <SwiperSlide key={data.id}>
                <Img src={language === 'ko' ? data.img : data.img_en} />
                <ContentWrapper>
                  <Category>{data.category}</Category>
                  <Title>{t(data.title)}</Title>
                  <Content>{t(data.content)}</Content>
                  <Button
                    type="button"
                    onClick={() => {
                      navigate(`/business/product`)
                    }}
                  >
                    {t(data.button)}
                  </Button>
                </ContentWrapper>
              </SwiperSlide>
            )
          })}
        </StyledSwiper>
      ) : (
        <StyleSwiper
          key="mo"
          loop={true}
          initialSlide={1}
          pagination={{
            type: 'progressbar',
            el: '.slideIndex_tech2',
          }}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {data.map((data) => (
            <SwiperSlide key={data.id}>
              <Content>
                <img
                  src={language === 'ko' ? data.img : data.img_en}
                  alt="video"
                />
              </Content>
            </SwiperSlide>
          ))}
        </StyleSwiper>
      )}
      <SliderIndexBox>
        <hr />
        <SlideIndex className="slideIndex_tech2" />
      </SliderIndexBox>
      <StyledSwiper
        key="pc_page"
        modules={[Pagination, Navigation, Autoplay]}
        direction="vertical"
        slidesPerView={1}
        loop={true}
        speed={500}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          type: 'progressbar',
          el: '.slideIndex_tech2',
        }}
        className="mySwiper"
      >
        {data.map((data) => {
          return <SwiperSlide key={data.id} />
        })}
      </StyledSwiper>

      <ViewMoreBtn onClick={clickBtn}>
        VIEW MORE
        <span>
          <img src="/assets/icons/mo_right_arrow.png" alt="arrow" />
        </span>
      </ViewMoreBtn>
    </Container>
  )
}

export default SliderProduct

const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;

  .swiper-wrapper {
    width: 1440px;
    height: 1024px;
  }

  .swiper-pagination {
    color: transparent;

    .swiper-pagination-current,
    .swiper-pagination-total {
      color: ${({ theme }) => theme.color.GPrimary};
    }

    .swiper-pagination-current {
      position: absolute;
      top: -355px;
      left: 60px;
      width: 19px;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;

      ::after {
        position: absolute;
        top: 50px;
        left: -17px;
        width: 50px;
        height: 0px;
        border: 0.5px solid #00bac7;
        transform: rotate(90deg);
        content: '';
      }
    }

    .swiper-pagination-total {
      position: absolute;
      top: -268px;
      left: 60px;
      width: 19px;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
    }
  }

  @media all and (max-width: 768px) {
    display: none;
  }
`
const Container = styled.div`
  position: relative;
  width: 1440px;
  height: 1024px;
  background: url('/assets/images/main/SliderProduct_back.png');

  .SwiperBody {
    position: absolute;
    top: 0;
  }
  @media all and (max-width: 768px) {
    padding: 5rem 2rem;
    width: 100%;
    height: 100%;
    background: url('/assets/images/main/mobile/product/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
`

const Img = styled.img`
  position: absolute;
  top: 200px;
  left: 180px;
  width: 600px;
`

const ContentWrapper = styled.div`
  position: absolute;
  top: 180px;
  left: 868px;
  width: 480px;
  color: ${({ theme }) => theme.color.GPrimary};
  ${({ theme }) => theme.mixins.flexBox('column', 'flex-start')}
`

const Category = styled.h3`
  ::before {
    position: absolute;
    left: 0;
    top: 11px;
    width: 30px;
    height: 2px;
    background-color: ${({ theme }) => theme.color.GPrimary};
    content: '';
  }
  padding-left: 42px;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.3em;
`

const Title = styled.h1`
  margin-top: 10px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 64px;
  line-height: 78px;
  @media all and (max-width: 768px) {
    font-size: 2.4rem;
  }
`

const Content = styled.p`
  margin-top: 40px;
  font-weight: 500;
  font-size: 22px;
  line-height: 50px;
  word-break: keep-all;
`

const Button = styled.button`
  margin-top: 160px;
  padding: 20px 50px;
  background: ${({ theme }) => theme.color.WPrimary};
  border: none;
  border-radius: 100px;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: ${({ theme }) => theme.color.GPrimary};
  cursor: pointer;
`
const Header = styled.header`
  position: relative;
  margin-top: 180px;
  padding-bottom: 643px;
  color: #00bac7;
  display: none;

  @media all and (max-width: 768px) {
    display: block;
    margin-top: 0;
    padding-bottom: 0;
  }
`

const Label = styled.div`
  display: none;
  @media all and (max-width: 768px) {
    display: block;

    hr {
      display: inline-block;
      position: absolute;
      top: 2px;
      left: -45px;
      width: 30px;
      height: 2px;
      border: none;
      background-color: #00bac7;
    }
    position: relative;
    margin-left: 40px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    color: #00bac7;

    line-height: 23px;
    letter-spacing: 5px;
  }
`
const SubText = styled.p`
  display: none;

  @media all and (max-width: 768px) {
    display: block;
    margin-top: 40px;
    font-weight: 500;
    color: #00bac7;
    white-space: pre-line;
    font-size: 22px;
    line-height: 2.5rem;
    max-width: 16rem;
    font-size: 1rem;
    margin-top: 1rem;
    word-break: keep-all;
  }
`

//slider - pagenation_fraction
const SliderIndexBox = styled.div`
  position: relative;
  width: 115px;
  height: 18px;
  bottom: 0;
  line-height: 18px;
  font-family: Montserrat;
  hr {
    ${({ theme }) => theme.mixins.positionCenterX('absolute')};
    width: 50px;
    border: 0.1px solid white;
    background-color: white;
  }
  @media all and (max-width: 768px) {
    width: 100%;

    hr {
      display: none;
    }

    .swiper-pagination-progressbar {
      background: rgba(0, 186, 199, 0.3);
      height: 0.13rem;
      width: 100%;
    }
    .swiper-pagination-progressbar-fill {
      background: #00bac7;
    }
  }
`
const SlideIndex = styled.p`
  display: flex;
  justify-content: space-between;
  width: 115px;
  color: transparent;
  text-align: center;
  span {
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: ${(props) => props.theme.color.WPrimary};
  }
  span::before {
    content: '0';
  }

  @media all and (max-width: 768px) {
    span::before {
      content: '';
    }
  }
`
const StyleSwiper = styled(Swiper)`
  position: static;
  margin-left: 0;
  width: 18rem;
  height: auto;
  margin: 0 auto;
  img {
    width: 100%;
  }
`

const ViewMoreBtn = styled.article`
  display: none;
  @media all and (max-width: 768px) {
    float: right;
    display: block;
    color: #00bac7;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px;
    cursor: pointer;
    letter-spacing: 0.3em;
    span {
      margin-left: 0.5rem;
    }
  }
`
