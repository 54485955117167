import styled from 'styled-components'
import PageTitle from '../../components/PageTitle'
import { useEffect, useState } from 'react'

function Recruit() {
  const subtext =
    window.screen.width > 768
      ? `우리 모두가 다시 안심할 수 있도록, 가치있는 여정에 함께하실 분을 기다립니다.`
      : '우리 모두가 다시 안심할 수 있도록,\n가치있는 여정에 함께하실 분을 기다립니다.'
  const [data, setData] = useState([])
  useEffect(() => {
    fetch('/data/Recruit/data.json')
      .then((res) => res.json())
      .then((data) => {
        setData(data)
      })
  }, [])

  return (
    <>
      <PageTitle title="Recruit" subtext={subtext} />
      <ContactWrapper>
        {data.map((data) => (
          <OccupationBox
            onClick={() => window.open(`${data.url}`, '_blank')}
            key={data.id}
          >
            <BoxHeader>
              <BoxHeaderIcon backgroundImg={data.img} />
              <BoxHeaderText>{data.title}</BoxHeaderText>
              <BoxHeaderIcon backgroundImg="/assets/icons/recruit/arrow.png" />
            </BoxHeader>
            <BoxBody>{data.content}</BoxBody>
          </OccupationBox>
        ))}
      </ContactWrapper>

      <PageTitle paddingT="20px" title="Work with Us" />
      <ContactWrapper>
        <LeftSectionWrapper>
          <IconBox>
            <Icon backgroundImg="/assets/icons/recruit/support.png" />
          </IconBox>
          <DescBox>
            {window.screen.width > 768 ? (
              <ContactTitle>SUPPORT</ContactTitle>
            ) : (
              <ContactTitle>⎯&nbsp;&nbsp;Support</ContactTitle>
            )}
            <ContactDesc>
              업무에만 집중할 수 있도록 가능한 모든 것을 지원합니다.
            </ContactDesc>
            <ContactDesc2>
              <li>• &nbsp;업무용 장비 지급 (노트북 및 듀얼모니터)</li>
            </ContactDesc2>
            <ContactDesc2>
              <li>• &nbsp;이유따위 묻지 않는 휴가 사용</li>
            </ContactDesc2>
            <ContactDesc2>
              <li>• &nbsp;사내 스터디 활동 지원</li>
            </ContactDesc2>
            <ContactDesc2>
              <li>• &nbsp;업무능력 향상을 위한 도서 및 교육비 지원</li>
            </ContactDesc2>
          </DescBox>
        </LeftSectionWrapper>
        <LeftSectionWrapper>
          <IconBox>
            <Icon backgroundImg="/assets/icons/recruit/culture_fit.png" />
          </IconBox>
          <DescBox>
            {window.screen.width > 768 ? (
              <ContactTitle>CULTURE FIT</ContactTitle>
            ) : (
              <ContactTitle>⎯&nbsp;&nbsp;Culture Fit</ContactTitle>
            )}

            <ContactDesc>
              우리는 이렇게 일하고, 이런 가치를 중요시합니다.
            </ContactDesc>
            <ContactDesc2>
              <li>
                • &nbsp;직원 개개인의 결정과 의견을 서로 믿고 존중하기 때문에,
                높은 자율성을 갖고 일할 수 있습니다.
              </li>
            </ContactDesc2>
            <ContactDesc2>
              <li>
                • &nbsp;회사에 도움이 되는 더 나은 것을 누구나, 언제나 제안할 수
                있습니다.
              </li>
            </ContactDesc2>
            <ContactDesc2>
              <li>
                • &nbsp;혼자 할 때 100만큼 잘해도 협업할 때 팀 퍼포먼스를
                떨어뜨리는 사람보다, 협업 잘하고 80만큼 잘하는 사람이 2배 더
                좋습니다.
              </li>
            </ContactDesc2>
          </DescBox>
        </LeftSectionWrapper>
        <LeftSectionWrapper>
          <IconBox>
            <Icon backgroundImg="/assets/icons/recruit/benefit.png" />
          </IconBox>
          <DescBox>
            {window.screen.width > 768 ? (
              <ContactTitle>BENEFIT</ContactTitle>
            ) : (
              <ContactTitle>⎯&nbsp;&nbsp;Benefit</ContactTitle>
            )}
            <ContactDesc>
              누구나 좋은 문화를 제안할 수 있고, 함께 더 좋은 문화를 만들어가고
              있어요.
            </ContactDesc>
            <ContactDesc2>
              <li>
                • &nbsp;3년 재직 시 2주(영업일 10일)의 Refresh(유급)휴가와 휴가
                지원비 300만원을 상여금으로 받게 됩니다.
              </li>
            </ContactDesc2>
            <ContactDesc2>
              <li>
                • &nbsp;본인 생일 시 해피버스데이 반차를, 결혼하신 분이라면
                결혼기념일 당일에 스페셜 반차를 제공합니다.
              </li>
            </ContactDesc2>
            <ContactDesc2>
              <li>
                • &nbsp;매년 설날과 추석을 기념하며 직원분들에게 감사한 마음을
                담은 선물을 드립니다.
              </li>
            </ContactDesc2>
          </DescBox>
        </LeftSectionWrapper>
        <LeftSectionWrapper style={{ 'margin-bottom': '180px' }}>
          <IconBox>
            <Icon backgroundImg="/assets/icons/recruit/cycle.png" />
          </IconBox>
          <DescBox>
            {window.screen.width > 768 ? (
              <ContactTitle>WORK CYCLE</ContactTitle>
            ) : (
              <ContactTitle>⎯&nbsp;&nbsp;Work cycle</ContactTitle>
            )}
            <ContactDesc>근무형태는 아래와 같습니다.</ContactDesc>
            <ContactDesc2>
              <li>
                • &nbsp;근무시간은 월요일 ~ 금요일, 오전 9~11시에 출근해 8시간
                근무합니다.
              </li>
            </ContactDesc2>
            <ContactDesc2>
              <li>
                • &nbsp;정규직으로 입사하여 3개월 간 수습기간이 적용됩니다. (
                수습기간 동안 임금은 100% 지급됩니다.)
              </li>
            </ContactDesc2>
            <ContactDesc2>
              <li>
                • &nbsp;사무실의 위치는 합정역에서 걸어서 5분, 상수역에서 걸어서
                7분정도 걸립니다.
              </li>
            </ContactDesc2>
          </DescBox>
        </LeftSectionWrapper>
      </ContactWrapper>
    </>
  )
}
const ContactWrapper = styled.section`
  position: relative;
  min-width: 1440px;
  padding: 0 60px 0 60px;
  background-color: ${(props) => props.theme.color.WPrimary};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 100px;

  @media all and (max-width: 768px) {
    min-width: 100%;
    margin-top: 3.5rem;
    padding: 0 1.5rem;
  }
`

const OccupationBox = styled.section`
  width: 630px;
  height: 250px;
  border-radius: 20px;
  margin-bottom: 80px;
  cursor: pointer;
  border-color: #eee;
  @media all and (max-width: 768px) {
    width: 100%;
    height: fit-content;
  }
`

const BoxHeader = styled.section`
  padding: 0 10px 0 10px;
  background: #00bac7;
  width: 100%;
  height: 100px;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  justify-content: space-between;

  @media all and (max-width: 768px) {
    height: 4rem;
  }
`

const BoxHeaderIcon = styled.section`
  width: 70px;
  height: 50px;
  background-image: url(${(props) => props.backgroundImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 25px 20px 0 20px;
  @media all and (max-width: 768px) {
    width: 2.5rem;
    margin: 0.5rem 1rem;
  }
`
const BoxHeaderText = styled.section`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  margin: 25px 10px 0 10px;
  font-size: 30px;
  line-height: 43px;
  text-align: center;
  color: #ffffff;
  @media all and (max-width: 768px) {
    margin-top: 0.6rem;
    font-size: 1rem;
  }
`

const BoxBody = styled.section`
  text-align: center;
  padding: 20px 100px 20px 100px;
  line-height: 35px;
  width: 100%;
  height: fit-content;
  border-radius: 1rem;
  box-shadow: 0 3px 5px rgb(0 0 0 / 10%);
  word-break: keep-all;
  @media all and (max-width: 768px) {
    padding: 2rem 1.5rem;
    height: fit-content;
  }
`

const LeftSectionWrapper = styled.div`
  padding: 0 auto 30px auto;
  display: flex;
  width: 100%;
  height: 50%;
  margin-bottom: 50px;
`

const IconBox = styled.div`
  @media all and (max-width: 768px) {
    display: none;
  }
`

const Icon = styled.div`
  width: 60px;
  height: 60px;
  background-image: url(${(props) => props.backgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1rem;
`

const DescBox = styled.div`
  margin: 0 auto 30px 40px;
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.backgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1rem;
  @media all and (max-width: 768px) {
    margin: 0 0.5rem;
    word-break: keep-all;
  }
`

const ContactTitle = styled.div`
  margin-top: 5px;
  margin-bottom: 20px;
  width: 500px;
  height: 40px;
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 0.2em;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #77cae4;
  @media all and (max-width: 768px) {
    width: 100%;
    font-size: 1.5rem;
    font-family: 'Montserrat';
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.1rem;
    line-height: 2.3rem;
    margin-bottom: 0.5rem;
  }
`

const ContactDesc = styled.div`
  margin-bottom: 20px;
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #666666;
  @media all and (max-width: 768px) {
    font-size: 0.9rem;
  }
`

const ContactDesc2 = styled.div`
  margin-bottom: 20px;
  color: #666666;
  list-style: none;

  @media all and (max-width: 768px) {
    font-size: 0.9rem;
    line-height: 1.7rem;
    margin-bottom: 1rem;
    margin-left: 0.8rem;
    list-style-position: inside;
    text-indent: -0.8rem;
  }
`
export default Recruit
