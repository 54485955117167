import styled from 'styled-components'
import PageTitle from '../../../components/PageTitle'
import { Trans, useTranslation } from 'react-i18next'

function History() {
  const { t } = useTranslation()
  const subtext = t('history_title')

  return (
    <>
      <PageTitle title="History" subtext={subtext} />
      <HistoryWrapper>
        <Content>
          <Container>
            <Point className="bigicon">
              <img src="/assets/icons/history_01.png" alt="포인트" />
            </Point>
            <TextBundle>
              <Year>2024</Year>

              <Story>{t('history_2024_1')}</Story>
              <Story>{t('history_2024_2')}</Story>
              <Story>{t('history_2024_3')}</Story>
              <Story>{t('history_2024_4')}</Story>
              <Story>{t('history_2024_5')}</Story>
              <Story>{t('history_2024_6')}</Story>
              <Story>{t('history_2024_7')}</Story>
              <Story>{t('history_2024_8')}</Story>
              <Story>{t('history_2024_9')}</Story>
              <Story>{t('history_2024_10')}</Story>
            </TextBundle>
          </Container>
          <Container>
            <Point className="smallicon">
              <img src="/assets/icons/history_02.png" alt="포인트" />
            </Point>
            <TextBundle>
              <Year>2023</Year>
              <Story>{t('history_2023_1')}</Story>
              <Story>{t('history_2023_2')}</Story>
              <Story>{t('history_2023_3')}</Story>
              <Story>{t('history_2023_4')}</Story>
            </TextBundle>
          </Container>
          <Container>
            <Point className="smallicon">
              <img src="/assets/icons/history_02.png" alt="포인트" />
            </Point>
            <TextBundle>
              <Year>2022</Year>
              <Story>{t('history_2022_1')}</Story>
              <Story>{t('history_2022_2')}</Story>
              <Story>{t('history_2022_3')}</Story>
            </TextBundle>
          </Container>
          <Container>
            <Point className="smallicon">
              <img src="/assets/icons/history_02.png" alt="포인트" />
            </Point>
            <TextBundle>
              <Year>2021</Year>
              <Story>{t('history_2021_1')}</Story>
              <Story>
                {t('history_2021_2')}
                <p>{t('history_2021_3')}</p>
              </Story>
              <Story>{t('history_2021_4')}</Story>
              <Story>{t('history_2021_5')}</Story>
              <Story>
                {t('history_2021_6')}
                <p>{t('history_2021_7')}</p>
              </Story>
              <Story>{t('history_2021_8')}</Story>
              <Story>{t('history_2021_9')}</Story>
            </TextBundle>
          </Container>
          <Container>
            <Point className="smallicon">
              <img src="/assets/icons/history_02.png" alt="포인트" />
            </Point>
            <TextBundle>
              <Year>2020</Year>
              <Story>{t('history_2020_1')}</Story>
              <Story>{t('history_2020_2')}</Story>
            </TextBundle>
          </Container>
          <Container>
            <Point className="smallicon">
              <img src="/assets/icons/history_02.png" alt="포인트" />
            </Point>
            <TextBundle>
              <Year>2019</Year>

              <Story>
                {t('history_2019_1')}
                <p>{t('history_2019_2')}</p>
              </Story>
            </TextBundle>
          </Container>
          <Container>
            <Point className="smallicon">
              <img src="/assets/icons/history_02.png" alt="포인트" />
            </Point>
            <TextBundle>
              <Year>2017</Year>
              <Story>
                {t('history_2017_1')}
                <p>{t('history_2017_2')}</p>
              </Story>
            </TextBundle>
          </Container>
          <Container>
            <Point className="bigicon">
              <img src="/assets/icons/history_01.png" alt="포인트" />
            </Point>
            <TextBundle>
              <Year>2016</Year>
              <Story>{t('history_2016_1')}</Story>
            </TextBundle>
          </Container>
          <Border />
        </Content>
      </HistoryWrapper>
    </>
  )
}

const HistoryWrapper = styled.section`
  position: relative;
  min-width: 1440px;
  background: url('/assets/images/about/history_bg.png');
  background-color: ${(props) => props.theme.color.WPrimary};
  @media all and (max-width: 768px) {
    min-width: 100%;
  }
`
const Content = styled.article`
  margin: 180px 0;
  padding-left: 135px;
  @media all and (max-width: 768px) {
    margin-top: 3.5rem;
    margin-bottom: 6rem;
    padding: 0 2rem 0 2.5rem;
  }
`
// history
const Container = styled.div`
  display: flex;
  margin-top: 40px;
  width: 1305px;
  .bigicon {
    left: -18px;
    top: 10px;
    z-index: 10;
  }
  .smallicon {
    z-index: 10;
    left: -6px;
    top: 17px;
    padding: 8px 20px 0 0;
  }
  @media all and (max-width: 768px) {
    margin-top: 2rem;
    width: 100%;
    .bigicon {
      top: 0.5rem;
    }

    .smallicon {
      top: 1rem;
    }
    word-break: keep-all;
  }
`
const Point = styled.div`
  position: relative;
`

// history - text
const TextBundle = styled.div`
  margin-left: 75px;
  @media all and (max-width: 768px) {
    margin-left: 0;
  }
`
const Year = styled.p`
  font-weight: 700;
  font-size: 60px;
  line-height: 60px;
  color: #333333;

  @media all and (max-width: 768px) {
    font-size: 2rem;
    line-height: 2.4rem;
    margin-top: 0.6rem;
  }
`
const Story = styled.div`
  margin: 50px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #6d6d6d;
  p {
    padding-top: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #aaaaaa;
  }

  mark {
    font-weight: 700;
    background: transparent;
  }
  @media all and (max-width: 768px) {
    font-size: 0.9rem;
    margin: 1rem 0 0.1rem 0;
    p {
      font-size: 0.8rem;
    }
  }
`
// border
const Border = styled.div`
  position: absolute;
  top: 10px;
  left: 135px;
  height: 100%;
  border-left: 4px solid ${(props) => props.theme.color.GTransparency};
  @media all and (max-width: 768px) {
    left: 2.53rem;
    height: 100%;
    top: 3rem;
  }
`

export default History
