import styled from 'styled-components'
import PageTitle from '../../../components/PageTitle'
import { useTranslation } from 'react-i18next'

function Introduce() {
  const { t } = useTranslation()
  const subtext = t('main_mission')

  return (
    <>
      <PageTitle title="Our Mission" subtext={subtext} />
      <List>
        <Item>
          <img
            className="icon"
            src="/assets/icons/business.png"
            alt="bussiness icon"
          />
          {window.screen.width > 768 ? (
            <Keyword>BUSINESS</Keyword>
          ) : (
            <Keyword>⎯ BUSINESS</Keyword>
          )}

          <Image>
            <img
              src="/assets/images/about/mission/business.png"
              alt="business"
            />
          </Image>
          <MissionP>{t('main_mission_business')}</MissionP>
        </Item>
        <Item isRight={true}>
          <img
            className="icon"
            src="/assets/icons/technology.png"
            alt="bussiness icon"
          />
          {window.screen.width > 768 ? (
            <Keyword>TECHNOLOGY</Keyword>
          ) : (
            <Keyword>⎯ TECHNOLOGY</Keyword>
          )}

          <Image>
            <img src="/assets/images/about/mission/tech.png" alt="business" />
          </Image>
          <MissionP>{t('main_mission_technology')}</MissionP>
        </Item>
        <Item>
          <img
            className="icon"
            src="/assets/icons/social.png"
            alt="bussiness icon"
          />
          {window.screen.width > 768 ? (
            <Keyword>SOCIAL</Keyword>
          ) : (
            <Keyword>⎯ SOCIAL</Keyword>
          )}

          <Image>
            <img src="/assets/images/about/mission/social.png" alt="business" />
          </Image>
          <MissionP>{t('main_mission_social')}</MissionP>
        </Item>
      </List>
    </>
  )
}

const List = styled.div`
  margin-top: 100px;
  padding: 0 60px;
  margin-bottom: 200px;
  width: 100%;
  ${({ theme }) => theme.mixins.flexBox('row', 'center', 'space-between')};
  @media all and (max-width: 768px) {
    flex-direction: column;
    padding: 0 2rem;
    margin-top: 0;
    margin-bottom: 5rem;
    align-items: center;
  }
`

const MissionP = styled.p`
  white-space: pre-line;
  text-align: center;
`

const Item = styled.div`
  ${({ theme }) => theme.mixins.flexBox('column')};

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 40px;
    color: ${({ theme }) => theme.color.TPrimary};
  }

  hr {
    display: none;
  }

  @media all and (max-width: 768px) {
    width: 100%;
    align-items: ${(props) => (props.isRight ? 'flex-end' : 'flex-start')};
    margin-top: 2rem;
    .icon {
      display: none;
    }
    p {
      font-size: 0.9rem;
      line-height: 2rem;
    }
  }
`

const Image = styled.div`
  margin-bottom: 30px;
  margin-top: 10px;
  @media all and (max-width: 768px) {
    width: 15rem;

    img {
      width: 100%;
    }
  }
`

const Keyword = styled.h3`
  margin: 24px 0 20px;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: 0.4em;
  color: ${({ theme }) => theme.color.GPrimary};
`

export default Introduce
