import styled from 'styled-components'

function Terms() {
  return (
    <ContactWrapper>
      <div id="wrapper">
        <div class="contents" id="fullpage" data-ui-parellax="wrapper">
          <div class="contents--outer-wrap section">
            <div class="contents-wrapper">
              <article style={{ lineHeight: '2rem', paddingTop: '2rem' }}>
                <p>릴리의지도 환불정책</p>
                <p>
                  <span>&nbsp;</span>
                </p>
                <p>
                  안녕하세요 릴리의 지도입니다. 릴리의지도는 인앱 구매를 통한
                  정기 결제로 유료 서비스를 사용하실 수 있습니다.
                </p>
                <p>
                  <br />
                  <b>릴리의지도 결제수단 설명</b>
                </p>
                <p>
                  릴리의지도는 iTunes, Google Play 또는 릴리의지도가 승인한 다른
                  앱 플랫폼(각각 “소프트웨어 스토어”라고 칭함)을 통해 제품 및
                  서비스를 구매(“인앱 구매”)할 수 있도록 제안할 수 있습니다.
                </p>
                <p>
                  회원이 인앱 구매를 하기로 선택하시는 경우, 회원은 이용자의
                  소프트웨어 스토어(“귀하의 IAP 계정”)에 있는 회원의 계정에
                  세부사항을 입력하라는 메시지를 받게 되며, 회원의 IAP 계정에는
                  구매 시점에 회원에게 공개된 조건과 회원의 IAP 계정에 적용되는
                  인앱 구매에 대한 일반 조건에 따라 인앱 구매에 대해 요금이
                  부과됩니다.
                </p>
                <p>
                  회원이 인앱 구매를 통해 자동으로 반복되는 정기 유료 구독
                  서비스를 구매하는 경우, 회원의 IAP 계정에는 회원이 취소할
                  때까지 계속하여 유료 구독 서비스에 대해 요금이 청구될
                  것입니다.
                </p>
                <p>
                  회원의 최초 유료 구독 서비스 약정 기간이 지나고 다시 후속 유료
                  구독 서비스 기간이 지나면, 회원의 유료 구독 서비스는 추가 해당
                  기간 동안 유료 구독 서비스 시에 동의한 가격으로 자동적으로
                  계속됩니다.
                </p>
                <p>
                  유료 구독 서비스의 자동 갱신을 원하지 않는 경우 또는 유료 구독
                  서비스를 변경하거나 해지하기를 원하는 경우, 릴리의지도에 있는
                  회원의 계정 또는 회원의 기기에서 릴리의지도 앱을 다른 방법으로
                  삭제하였다 하더라도 귀하는 귀하의 IAP 계정에 로그인하고 지시를
                  따라서 유료 구독 서비스를 취소하셔야 합니다. 릴리의지도의 계정
                  또는 회원의 기기의 릴리의지도 앱을 삭제하였다 하더라도
                  릴리의지도의 유료 구독 서비스는 취소되지 않습니다.
                </p>
                <p>
                  릴리의지도는 회원의 IAP 계정을 통해 유료 구독 서비스를
                  취소하기 전까지 회원의 IAP 계정으로 청구된 모든 자금을
                  보유합니다.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  <b>결제요금</b>
                </p>
                <p>
                  유료 서비스 요금은 수시로 변경될 수 있으며, 릴리의지도는 가격
                  인하 또는 특가 제안의 경우에도 가격 보호 또는 환불을 제공하지
                  아니합니다. 회원은 주문하는 모든 유료 서비스에 대하여
                  지불하기로 동의합니다.
                </p>
                <p>
                  릴리의지도는 해당되는 유료 서비스 제안에 명시된 가격, 그리고
                  해당되는 세금, 은행 수수료 및 통화 등락과 관련된 모든 추가
                  금액을 사용자의 결제금액으로 청구합니다.
                </p>
                <p>
                  미결제 금액에 대한 책임은 회원에게 있습니다. 유효기간 만료,
                  잔고 부족 등 사유로 결제가 정상적으로 처리되지 않았는데도
                  회원이 계정을 해지하지 않는 경우, 유효한 결제 수단에 청구가
                  완료될 때까지 회원의 서비스 이용이 제한될 수 있습니다.
                </p>
                <p>
                  발급자가 회원에게 해외 거래 수수료나 기타 결제 수단 처리 관련
                  수수료 등의 수수료를 청구할 수 있습니다. 현지 세금은 사용된
                  결제 수단에 따라 달라질 수 있습니다. 자세한 사항은 결제 수단
                  서비스 제공업체를 통해 확인하시기 바랍니다.
                </p>
                <p>
                  <br />
                  <b>결제수단</b>
                </p>
                <p>
                  릴리의지도 유료 서비스를 이용하려면 하나 이상의 결제 수단을
                  제공해야 합니다. 일부 결제 수단의 경우, 발급자가 회원에게 해외
                  거래 수수료나 기타 결제 수단 처리 관련 수수료 등의 수수료를
                  청구할 수 있습니다. 현지 세금은 사용된 결제 수단에 따라 달라질
                  수 있습니다. 자세한 사항은 결제 수단 서비스 제공업체를 통해
                  확인하시기 바랍니다.
                </p>
                <p>
                  회원은 릴리의지도 어플리케이션 내 '계정' 페이지로 이동하여
                  결제 수단을 업데이트할 수 있습니다. 업데이트를 하는 경우,
                  회원은 릴리의지도가 해당 결제 수단으로 계속 청구하는 것을
                  승인하는 것입니다. 회원은 기본 결제 수단이 거부되거나 가입
                  요금 결제에 더 이상 사용될 수 없는 경우, 릴리의지도가 회원의
                  계정에 연결된 다른 결제 수단으로 청구하는 것을 승인합니다."
                </p>
                <p>
                  <br />
                  <b>정기결제해지</b>
                </p>
                <p>
                  회원은 언제라도 릴리의지도 유료 서비스를 해지할 수 있으며, 이
                  경우 결제 주기가 종료될 때까지는 릴리의지도 서비스를 계속
                  이용할 수 있습니다.
                </p>
                <p>
                  유료 서비스를 변경하거나 해지하기를 원하는 경우, 회원의 계정을
                  삭제했거나 기기에서 릴리의지도 앱을 삭제했더라도 제3자
                  계정(또는릴리의지도 계정 설정)으로 로그인하고 절차에 따라 유료
                  구독 서비스를 해지하거나 취소해야 합니다.
                </p>
                <p>
                  릴리의지도는 릴리의지도 또는 해당하는 경우 제3자 계정에서
                  귀하가 유료 서비스를 해지하거나 취소하기 전까지 귀하의 결제
                  방법으로 모든 자금을 청구할 권리를 보유합니다. 유료 서비스를
                  해지 또는 취소하는 경우, 회원은 당시의 유료 서비스 기간이 끝날
                  때까지 유료 서비스를 이용할 수 있으며 해당 유료 서비스 기간이
                  만료된 후 유료 서비스는 갱신되지 않습니다.
                </p>
                <p>
                  <br />
                  <b>환불정책</b>
                </p>
                <p>
                  관련 법률이 허용하는 범위에서 결제 금액은 환불되지 않으며,
                  유료 서비스를 이용하지 않은 기간이나 사용하지 않은 기능에 대한
                  환불은 제공되지 않습니다.
                </p>
                <p>
                  유료 서비스를 해지하려면 '계정' 페이지로 이동하여 해지 관련
                  안내를 따르시기 바랍니다. 유료 서비스를 해지하는 경우, 현재
                  결제 주기가 종료될 때 자동으로 종료됩니다. 계정이 종료되는
                  시점은 '계정' 페이지에서 확인할 수 있습니다. "
                </p>
                <p>
                  <b>■ 결제취소 (청약철회) 조건</b>
                </p>
                <p>
                  회사와 유료 서비스 이용에 관한 계약을 체결한 회원은
                  구매계약일에 7일 이내에 별도의 수수료,위약금 등의 부담 없이
                  청약철회를 할 수 있습니다.
                </p>
                <p>
                  회원은 다음에 해당하는 경우에는 청약철회를 할 수 없습니다.
                </p>
                <p>
                  1. 회원에게 책임이 있는 사유로 재화 등이 멸실되거나 훼손된
                  경우
                </p>
                <p>2. 회원이 재화를 사용 또는 일부 소비한 경우</p>
                <p>가. 구매 즉시 사용되거나 적용되는 유료 콘텐츠</p>
                <p>
                  나. 부가적인 혜택이 제공되는 콘텐츠 또는 묶음형 판매
                  콘텐츠에서 추가혜택이 사용되거나 일부가 사용된 콘텐츠
                </p>
                <p>
                  다. 개봉행위를 사용으로 볼 수 있거나 개봉 시 효용이 결정되는
                  콘텐츠의 개봉행위가 있는 경우
                </p>
                <p>
                  3. 콘텐츠의 제공이 개시된 경우. 다만, 가분적 콘텐츠로 구성된
                  계약의 경우 제공이 개시되지 아니한 부분은 그러하지 아니합니다.
                </p>
                <p>
                  ③ 회사는 제2항 제2호 내지 제3호의 규정에 따라 청약철회 등이
                  불가능한 재화 등의 경우에는 그 사실을 재화 등의 포장이나
                  그밖에 회원이 쉽게 알 수 있는 곳에 명확하게 적시하거나 시험
                  사용 상품을 제공하는 등의 방법으로 청약철회 등의 권리 행사가
                  방해받지 아니하도록 조치하여야 합니다. 다만, 제2항 제3호에
                  따라 회원이 청약철회 등을 할 수 없는 경우에는 청약철회 등이
                  불가능하다는 사실의 표시와 함께 시험사용상품을 제공하는 등의
                  방법으로 청약철회 등의 권리 행사가 방해받지 아니하도록 합니다.
                  만약 회사가 이러한 조치를 하지 아니한 경우에는 제2항 제2호
                  내지 제3호의 청약철회 제한사유에도 불구하고 회원은 청약철회를
                  할 수 있습니다.
                </p>
                <p>
                  <br />
                  <b>환불요청방법 (Apple)</b>
                </p>
                <p>
                  iPhone 또는 iPad를 사용해 구매했거나, iTunes를 통해 릴리의지도
                  유료 서비스를 결제한 경우, 환불은 릴리의지도가 아닌 Apple
                  지원팀에 의해 처리됩니다. 환불을 요청하려면 iTunes로 이동하여
                  귀하의 Apple ID를 클릭하고, “구매 내역”을 선택한 다음, 거래를
                  찾아 “문제 보고”를 누릅니다.
                  https://getsupport.apple.com에서도 요청서를 제출할 수
                  있습니다.
                </p>
                <p>
                  귀하가 취소권을 행사하는 경우(Apple이 권한을 보유한 Apple ID를
                  통해 구매한 경우 제외), 당사는 귀하로부터 받은 모든 지불금을
                  부당하게 지체하지 않고, 당사가 귀하의 계약 취소 결정을 통지를
                  받은 날부터 14일 이내에 환불하거나 Google에 환불을 요청합니다.
                  당사는 최초 거래에서 귀하가 사용한 동일한 결제 수단을 사용하여
                  그러한 환불을 진행합니다. 어떤 경우에도 귀하에게 환불로 인한
                  수수료는 부과되지 않습니다.
                </p>
                <p>
                  <b>* 예외사항</b>
                </p>
                <p>다음의 경우, 환불 대상에서 제외될 수 있습니다.</p>
                <p>
                  1. 사기 또는 환불 남용이나 반대 요구를 제기할 수 있는 속임수의
                  증거가 발견될 경우
                </p>
                <p>
                  2. Apple ID의 기밀성과 보안을 유지하지 않아 생기는 무단 사용에
                  관한 손실
                </p>
                <p>3. 알 수 없는 청구의 경우</p>
                <p>1) 최근 구입 목록 및 구입 그룹 확인</p>
                <p>2) 자동 갱신된 구독 확인</p>
                <p>3) 가족 구성원이 구입 확인</p>
                <p></p>
                <p>
                  <br />
                  <b>환불요청방법 (Google)</b>
                </p>
                <p>
                  Google Play 스토어 계정을 이용하시는 경우, 해당되는 상황에
                  따라 Google Play 스토어 또는 릴리의지도고객 지원팀으로
                  연락하시기 바랍니다.
                </p>
                <p>
                  - 결제 기준 48시간 이내 결제취소 : Google Play 홈페이지 ▶
                  ‘구독 및 서비스’에서 확인할 수 있습니다.
                </p>
                <p>
                  - 결제 기준 48시간 이후 상품결함 사유 : 릴리의지도 고객지원팀
                  이메일 (contact@spresto.net)
                </p>
                <p>
                  Google Play 스토어 주문 번호는 주문 확인 이메일 또는 Google
                  월렛에 로그인하여 찾으실 수 있습니다.
                </p>
                <p>
                  <b>* 예외사항</b>
                </p>
                <p>
                  다음의 경우 별도의 정책으로 적용되거나 또는 환불 대상에서
                  제외될 수 있습니다.
                </p>
                <p>
                  1. 인증절차를 통해 계정을 보호하지 않는 경우, 환불 대상에서
                  제외될 수 있습니다.
                </p>
                <p>2. 동일한 App/Game 1회만 반품 및 환불이 가능합니다.</p>
                <p>
                  3. 편의점/코드를 이용 및 구매한 경우에는 Google credit으로
                  적립됩니다.
                </p>
                <p>
                  <br />
                  <br />
                  <b>
                    릴리의지도 고객센터:
                    <a href="mailto:contact@spresto.net">contact@spresto.net</a>
                  </b>
                </p>
              </article>
            </div>
          </div>
        </div>
      </div>
    </ContactWrapper>
  )
}

const ContactWrapper = styled.section`
  position: relative;
  min-width: 1440px;
  padding: 0 60px 0 60px;
  background-color: ${(props) => props.theme.color.WPrimary};
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 100px;

  @media all and (max-width: 768px) {
    word-break: keep-all;
    min-width: 100%;
    width: 100%;
    margin-top: 3.5rem;
    padding: 0 1.5rem;
  }
`

const Title = styled.div`
  font-size: 36px;
  font-weight: 700;
  margin-top: 50px;

  @media all and (max-width: 768px) {
    min-width: 100%;
    margin-top: 3.5rem;
    line-height: 3.2rem;
    padding: 0 1.5rem;
  }
`

const SubTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-top: 60px;

  @media all and (max-width: 768px) {
    min-width: 100%;
    margin-top: 3.5rem;
    padding: 0 1.5rem;
  }
`

const Description = styled.div`
  font-size: 18px;
  font-weight: 400;
  margin-top: 20px;

  @media all and (max-width: 768px) {
    min-width: 100%;
    margin-top: 0.5rem;
    /* margin-bottom: 1rem; */
    line-height: 2rem;
    padding: 0 1.5rem;
  }
`

const SubDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  @media all and (max-width: 768px) {
    min-width: 100%;
    line-height: 1.5rem;
    padding: 0 1.5rem;
  }
`

const ImgWrapper = styled.div`
  @media all and (max-width: 768px) {
    img {
      margin-top: 3rem;
      width: 21rem;
    }
  }
`
const BtnBox = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  button {
    width: 216px;
    height: 53px;
    border: 1.5px solid ${(props) => props.theme.color.GPrimary};
    border-radius: 10px;
    cursor: pointer;
  }
  @media all and (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    position: static;
    margin-bottom: 2.5rem;
    button {
      width: 10.5rem;
      background-size: 7.5rem;
      height: 3rem;
      margin-right: 0.3rem;
    }
  }
`

const AppStoreBtn = styled.button`
  /* margin-right: 3rem; */
  background: url('/assets/icons/appstore.png') no-repeat center;
`
const GooglePlayBtn = styled.button`
  background: url('/assets/icons/googleplay.png') no-repeat center;
`

export default Terms
