import styled from 'styled-components'

function Film() {
  return (
    <ContactWrapper>
      <Title>불법카메라 탐지카드 사용가이드</Title>
      <SubTitle>탐지카드 원리</SubTitle>
      <MainDescription>
        적외선 카메라 원리로, 반사율이 좋은 색을 가진 플래시 빛이 불법 카메라
        렌즈에 닿으면 반사되어 렌즈 부분이 부각되어 보이는 방법입니다.
      </MainDescription>

      <SubTitle className="howtouse">사용 방법</SubTitle>

      <DescWrapper className="first">
        <ImgWrapper>
          <img src="/assets/images/film/newuse3.png" alt="" />
        </ImgWrapper>
        <DescriptionWrapper>
          <Description>
            1. 스마트폰에서 동영상 촬영 모드를 켜고 플래시를 작동 시킵니다.
          </Description>
          <SubDescription>
            ※ 일반 사진 모드는 플래시가 계속 꺼져서 불편할 수 있어요.
          </SubDescription>
        </DescriptionWrapper>
      </DescWrapper>

      <DescWrapper>
        <ImgWrapper>
          <img src="/assets/images/film/newuse1.png" alt="" />
        </ImgWrapper>

        <DescriptionWrapper>
          <Description>
            2. 탐지필름을 카메라 렌즈와 플래시쪽 동시에 밀착한 후 의심가는
            물체를 비춰봅니다.
          </Description>
        </DescriptionWrapper>
      </DescWrapper>

      <DescWrapper>
        <ImgWrapper>
          <img src="/assets/images/film/newuse2.png" alt="" />
        </ImgWrapper>
        <DescriptionWrapper>
          <Description className="pc">
            3. 카메라 렌즈가 있다면, 렌즈에 빛이 반사되어 화면에 반짝입니다.
          </Description>
          <Description className="pc">
            더 정확한 탐지가 필요하다면, 대표 불법카메라 탐지앱 "릴리의지도"를
            사용해보세요!
          </Description>
          <Description className="mo">
            3. 카메라 렌즈가 있다면, 렌즈에 빛이 반사되어 화면에 반짝입니다. 더
            정확한 탐지가 필요하다면, 대표 불법카메라 탐지앱 "릴리의지도"를
            사용해보세요!
          </Description>
        </DescriptionWrapper>
      </DescWrapper>

      <IframeWrapper>
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/OaxEMDZEaQk"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </IframeWrapper>

      <ReliefWrapper>
        <Title>Relief’s Map</Title>
        <SubTitle>불법카메라 탐지 어플 ‘릴리의 지도’</SubTitle>
        <MainDescription className="pc">
          비싼 탐지기기보다 압도적으로 높은 탐지율을 가진, 국내 유일무이,
          스마트폰 AI 비전의 불법카메라 탐지 기능을 제공하는 앱서비스입니다.
        </MainDescription>
        <ReliefDescWrapper>
          <ReliefMobileWrapper className="mo">
            <img src="/assets/images/film/mockup.png" alt="" />
            <img
              className="func"
              src="/assets/images/film/func_mo.png"
              alt=""
            />
            <MainDescription>
              비싼 탐지기기보다 압도적으로 높은 탐지율을 가진, 국내 유일무이,
              스마트폰 AI 비전의 불법카메라 탐지 기능을 제공하는 앱서비스입니다.
            </MainDescription>
            <BtnBox>
              <AppStoreBtn
                onClick={() =>
                  window.open(
                    `https://apps.apple.com/kr/app/id1500120201`,
                    '_blank'
                  )
                }
              />

              <GooglePlayBtn
                onClick={() =>
                  window.open(
                    `https://play.google.com/store/apps/details?id=com.spresto.safe.android&hl=ko&gl=US`,
                    '_blank'
                  )
                }
              />
            </BtnBox>
          </ReliefMobileWrapper>

          <ReliefLeftWrapper className="pc">
            <img src="/assets/images/film/mockup.png" alt="" />
          </ReliefLeftWrapper>

          <ReliefRightWrapper className="pc">
            <ReliefRightRow>
              <img src="/assets/images/film/func1.png" alt="" />
            </ReliefRightRow>
            <ReliefRightRow>
              <img src="/assets/images/film/func2.png" alt="" />
            </ReliefRightRow>
            <ReliefRightRow>
              <BtnBox>
                <AppStoreBtn
                  onClick={() =>
                    window.open(
                      `https://apps.apple.com/kr/app/id1500120201`,
                      '_blank'
                    )
                  }
                />

                <GooglePlayBtn
                  onClick={() =>
                    window.open(
                      `https://play.google.com/store/apps/details?id=com.spresto.safe.android&hl=ko&gl=US`,
                      '_blank'
                    )
                  }
                />
              </BtnBox>
            </ReliefRightRow>
          </ReliefRightWrapper>
        </ReliefDescWrapper>
      </ReliefWrapper>
    </ContactWrapper>
  )
}

const ContactWrapper = styled.div`
  position: relative;
  min-width: 1440px;
  padding: 0 60px 0 60px;
  background-color: ${(props) => props.theme.color.WPrimary};
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 100px;
  .howtouse {
    margin-top: 100px;
  }

  @media all and (max-width: 768px) {
    word-break: keep-all;
    min-width: 100%;
    width: 100%;
    margin-top: 3.5rem;
    padding: 0 1.5rem;
    .howtouse {
      margin-top: 4rem;
    }
  }
`

const DescWrapper = styled.div`
  display: flex;
  margin-top: 30px;

  @media all and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const DescriptionWrapper = styled.div`
  display: flex;
  padding-top: 100px;
  padding-left: 100px;
  flex-direction: column;

  .mo {
    display: none;
  }
  @media all and (max-width: 768px) {
    width: 100%;
    padding: 0;

    .pc {
      display: none;
    }
    .mo {
      display: block;
    }
  }
`

const Title = styled.div`
  font-size: 48px;
  font-weight: 400;
  margin-top: 100px;
  color: #00bac7;
  @font-face {
    font-family: 'nexen_b';
    src: url('/assets/fonts/nexen_b.ttf') format('truetype'),
      url('/assets/fonts/Nexen_B.woff') format('woff'),
      url('/assets/fonts/Nexen_B.woff2') format('font-woff2');
  }
  font-family: 'nexen_b';
  font-style: normal;

  @media all and (max-width: 768px) {
    min-width: 100%;
    width: 100%;
    font-size: 1.45rem;
    margin-top: 3.5rem;
    line-height: 3.2rem;
  }
`

const SubTitle = styled.div`
  font-size: 30px;
  font-weight: 500;
  margin-top: 50px;

  @media all and (max-width: 768px) {
    min-width: 100%;
    margin-top: 1.5rem;
    font-size: 1.3rem;
  }
`

const MainDescription = styled.div`
  font-size: 22px;
  font-weight: 400;
  margin-top: 20px;
  color: #666666;

  @media all and (max-width: 768px) {
    min-width: 100%;
    width: 100%;
    margin-top: 1rem;
    line-height: 2rem;
    font-size: 0.9rem;
  }
`

const Description = styled.div`
  font-size: 22px;
  font-weight: 700;
  margin-top: 20px;
  color: #666666;

  @media all and (max-width: 768px) {
    min-width: 100%;
    width: 100%;
    margin-top: 0rem;
    font-size: 0.9rem;
    line-height: 2rem;
  }
`

const SubDescription = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: #666666;
  margin-top: 20px;
  @media all and (max-width: 768px) {
    min-width: 100%;
    font-size: 0.8rem;
    margin-top: 0.7rem;
  }
`

const ImgWrapper = styled.div`
  width: 300px;

  img {
    width: 100%;
  }

  @media all and (max-width: 768px) {
    text-align: center;
    img {
      width: 80%;
    }
  }
`
const BtnBox = styled.div`
  bottom: 0;
  button {
    width: 190px;
    height: 53px;
    border: 1.5px solid ${(props) => props.theme.color.GPrimary};
    border-radius: 10px;
    cursor: pointer;
  }
  @media all and (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    position: static;
    margin-top: 2rem;
    button {
      width: 10rem;
      background-size: 7.5rem;
      height: 3rem;
      margin-right: 0.3rem;
    }
  }
`

const AppStoreBtn = styled.button`
  margin-right: 30px;
  background: url('/assets/icons/appstore.png') no-repeat center;
`
const GooglePlayBtn = styled.button`
  background: url('/assets/icons/googleplay.png') no-repeat center;
`

const IframeWrapper = styled.div`
  text-align: center;
  width: 100%;
  height: 670px;
  margin-top: 50px;
  padding: 50px 100px;
  @media all and (max-width: 768px) {
    width: 100%;
    height: 12rem;
    padding: 0;
  }
`

const ReliefWrapper = styled.div`
  width: 100%;
  margin-bottom: 100px;
  @media all and (max-width: 768px) {
    .pc {
      display: none;
    }
  }
`

const ReliefLeftWrapper = styled.div``
const ReliefRightWrapper = styled.div`
  margin-top: 30px;
`
const ReliefDescWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  @media all and (max-width: 768px) {
    .pc {
      display: none;
    }
  }
`
const ReliefRightRow = styled.div`
  margin-top: 90px;
`
const ReliefMobileWrapper = styled.div`
  display: none;
  @media all and (max-width: 768px) {
    display: block;
    img {
      width: 100%;
    }
    .func {
      width: 80%;
      margin-top: 2rem;
    }
  }
`

export default Film
