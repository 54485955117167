import Mission from './Mission/Mission'
import Partnership from './Partnership/Partnership'
import SliderMain from './SliderMain/SliderMain'

import SliderNews from './SliderNews/SliderNews'
import SliderPatent from './SliderPatent/SliderPatent'
import SliderProduct from './SliderProduct/SliderProduct'
import SliderTechnology from './SliderTechnology/SliderTechnology'

function Main() {
  return (
    <>
      <SliderMain />
      <Mission />
      <SliderTechnology />
      <SliderProduct />
      <SliderNews />
      <SliderPatent />
      <Partnership />
      {/* <SliderMembers /> */}
    </>
  )
}

export default Main

// styled-components
