import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

function Footer() {
  const { t } = useTranslation()
  return window.screen.width > 768 ? (
    <Container>
      <Logo src="/assets/icons/logo_white.svg" />
      <InfoGroup>
        <span>© 2021 sPresto.Co.,Ltd. All Rights Reserved.</span>
        <List>{t('fotter')}</List>
      </InfoGroup>
      <SubInfoGroup>
        <span className="contact">CONTACT</span>
        <List>02-6954-1013 ┃ contact@spresto.net</List>
      </SubInfoGroup>
      <SubInfoGroup>
        <span>LOCATION</span>
        <Box>
          <List>
            서울 오피스 : 서울특별시 마포구 동교로 174 10층 ┃ 10th floor, 174,
            Donggyo-ro, Mapo-gu, Seoul, Republic of Korea
          </List>
          <List>
            천안오피스 : 충청남도 천안시 서북구 두정역서 5길 4, 403호 ┃ 4,
            Dujeongyeokseo 5-gil, Seobuk-gu, Cheonan-si, Chungcheongnam-do,
            Republic of Korea
          </List>
        </Box>
      </SubInfoGroup>
    </Container>
  ) : (
    <Container>
      <Logo src="/assets/icons/logo_white.svg" />
      <SubInfoGroup>
        <span className="contact">CONTACT</span>
        <List>02-6954-1013 ┃ contact@spresto.net</List>
      </SubInfoGroup>
      <SubInfoGroup>
        <span>LOCATION</span>
        <List>서울 오피스: 서울특별시 마포구 동교로 174 10층</List>
      </SubInfoGroup>
      <InfoGroup>
        <List className="company">{t('footer')}</List>
        <span>© 2021 sPresto.Co.,Ltd. All Rights Reserved.</span>
      </InfoGroup>
    </Container>
  )
}

export default Footer

const Container = styled.div`
  width: 1440px;
  background-color: #272d3e;
  padding: 60px 100px 40px;
  color: ${({ theme }) => theme.color.WPrimary};

  span {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-right: 40px;
  }
  @media all and (max-width: 768px) {
    width: 100%;
    padding: 3rem 2rem;
  }
`

const Logo = styled.img`
  width: 130px;
  margin-bottom: 20px;
  @media all and (max-width: 768px) {
    width: 7rem;
  }
`

const InfoGroup = styled.div`
  ${({ theme }) => theme.mixins.flexBox('row', 'center', 'space-between')}
  margin-bottom: 60px;
  span {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  @media all and (max-width: 768px) {
    flex-direction: column;
    align-items: start;
    margin-bottom: 0;

    .company {
      margin-top: 1.5rem;
      font-size: 0.6rem;
    }

    span {
      font-size: 0.8rem;
      font-weight: 400;
    }
  }
`
const SubInfoGroup = styled.div`
  ${({ theme }) => theme.mixins.flexBox('row', 'flex-start', 'flex-start')}
  margin-bottom: 20px;
  span {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  .contact {
    letter-spacing: 0.07em;
  }
  @media all and (max-width: 768px) {
    margin-bottom: 0rem;
    span {
      font-size: 0.8rem;
      margin-right: 1rem;
    }

    .contact {
      letter-spacing: 0.07em;
    }
  }
`
const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const List = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  @media all and (max-width: 768px) {
    font-size: 0.7rem;
    padding-bottom: 0.1rem;
  }
`
