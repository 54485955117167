import { useEffect, useState } from 'react'
import styled from 'styled-components'
import PageTitle from '../../../components/PageTitle'
import { useTranslation } from 'react-i18next'

function Tech() {
  const { t } = useTranslation()
  const PageTitleSubText = t('tech_title')

  const [data, setData] = useState([])
  useEffect(() => {
    fetch('/data/Business/Tech/data.json')
      .then((res) => res.json())
      .then((data) => {
        setData(data)
      })
  }, [])

  return (
    <>
      <PageTitle title="Technology" subtext={PageTitleSubText} />
      <Containers>
        {data.map((data) => (
          <Container key={data.id} className="container">
            <ImgWrapper className="imgWrapper">
              <Background className="background" />
              <div className="imgWrapper_container">
                <Video
                  className="video"
                  src={data.image}
                  onClick={() => {
                    window.open(`${data.url}`, '_blank')
                  }}
                />
                <PlayButton
                  onClick={() => {
                    window.open(`${data.url}`, '_blank')
                  }}
                  src="/assets/icons/playbtn.png"
                  className="playButton"
                />
              </div>
            </ImgWrapper>
            <ContentWrapper>
              <Icon src={data.icon} />
              <Title>{t(data.title)}</Title>
              <MoVideo
                onClick={() => {
                  window.open(`${data.url}`, '_blank')
                }}
                src={data.image}
              />
              <PlayButton
                onClick={() => {
                  window.open(`${data.url}`, '_blank')
                }}
                src="/assets/icons/playbtn.png"
                className="playButton mo"
              />
              <Contents>
                <Bold>{t(data.contentBold)}</Bold>
              </Contents>
            </ContentWrapper>
          </Container>
        ))}
      </Containers>
    </>
  )
}

export default Tech

const Containers = styled.div`
  margin: 10px 0 170px;
  .container:nth-child(even) {
    ${({ theme }) =>
      theme.mixins.flexBox('row-reverse', 'center', 'space-between')}
    .imgWrapper {
      justify-content: flex-end;
      .imgWrapper_container {
        position: absolute;
        width: 100%;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .background {
        background-image: url('/assets/images/business/Tech_back_02.png');
      }
      .video {
        position: absolute;
      }
      .playButton {
        position: absolute;
      }
    }
  }
  @media all and (max-width: 768px) {
    margin-bottom: 6rem;
  }
`

const Container = styled.div`
  margin: 90px 60px;
  ${({ theme }) => theme.mixins.flexBox('row', 'center', 'space-between')}
  gap: 20px;
  @media all and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    margin: 4rem 0;
    padding: 0 1.5rem;
    position: relative;
  }
`

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  .imgWrapper_container {
    position: absolute;
    width: 100%;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media all and (max-width: 768px) {
    display: none;
  }
`

const Background = styled.div`
  width: 462px;
  height: 571px;
  background-image: url('/assets/images/business/Tech_back_01.png');
`

const Video = styled.img`
  position: absolute;

  width: 412px;
  height: 392px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
`

const MoVideo = styled.img`
  display: none;
  @media all and (max-width: 768px) {
    display: block;
    margin-top: 1rem;
    width: 100%;
    height: 15rem;
    object-fit: cover;
    border-radius: 1.5rem;
  }
`

const PlayButton = styled.img`
  position: absolute;

  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(25px);
  border-radius: 62px;
  @media all and (max-width: 768px) {
    left: 50%;
    top: 14.5rem;
    transform: translate(-50%, -50%);
    filter: opacity(0.5);
  }
`

const ContentWrapper = styled.div`
  ${({ theme }) => theme.mixins.flexBox('column')}
  width: 100%;
  .mo {
    display: none;
  }
  @media all and (max-width: 768px) {
    position: relative;
    .mo {
      display: block;
      background: rgba(255, 255, 255, 0.4);
      backdrop-filter: blur(25px);
      border-radius: 62px;
    }
  }
`

const Icon = styled.img`
  width: 60px;
  @media all and (max-width: 768px) {
    width: 2.5rem;
  }
`

const Title = styled.h3`
  text-align: center;
  margin-top: 30px;
  font-weight: 700;
  font-size: 30px;
  line-height: 43px;
  color: #333333;
  word-break: keep-all;
  @media all and (max-width: 768px) {
    font-size: 1rem;
    line-height: 2rem;
    margin-top: 1.3rem;
    color: #666666;
  }
`

const Contents = styled.div`
  margin-top: 30px;
  width: 560px;
  font-weight: 400;
  font-size: 22px;
  line-height: 40px;
  white-space: pre-wrap;
  text-align: center;
  @media all and (max-width: 768px) {
    width: 100%;
    font-size: 0.9rem;
    line-height: 1.8rem;
    float: left;
    text-align: left;
    margin-top: 1.5rem;
  }
`

const Bold = styled.strong`
  color: ${({ theme }) => theme.color.TPrimary};
`

const Content = styled.span`
  color: ${({ theme }) => theme.color.TSecondary};
  word-break: keep-all;
`
